#basic-downloader{
   background-color: #fff;
   height: 100%;

   color:#888;

   text-align: center;

   .basic-downloader-title{
      margin-top: 60px;
      display: inline-block;
      font-size: 30px;
   }

   .file-downloader-icon{
      margin-top: 30px;
      display: block;
      font-size: 200px;
   }

   .file-downloader-anchor-icon{
      font-style: none!important;
      color:#888!important;
      text-decoration: none;
   }

   .file-downloader-anchor{
      margin-top: 30px;
      display: inline-block;
      font-size: 20px;
   }
   
   .downloading-files-dangerous{
      margin-top: 20px;
   }
}