.homework-edit {
    padding-top: 20px;
}

.homework-preview-link {
    margin-left: -14px;
    margin-top: 7px;
    display: block;
}

.dropzone {
    position: relative; 
    width: 528px; 
    height: 100px; 
    border-width: 1.5px; 
    border-color: lightgray; 
    border-style: dashed; 
    border-radius: 5px;
    color:lightgray;
    text-align: center;
}
.toolbar {
    border: 1px solid #ddd;
    border-bottom: 0px;
    color: #333;
    background-color: #f5f5f5;
}

.homework-description {
    resize: vertical;
    min-height: 150px;
    border-radius: 0!important;
}

.dropdown-menu {
  background: #fff!important;
  background-color: #fff!important;
}
