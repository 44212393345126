#image-viewer{
   background-color: #fff;
   height: 100%;

   color:#888;

   text-align: center;

   .image-viewer-title{
      margin-top: 60px;
      display: inline-block;
      font-size: 30px;
   }

   img
   {
      max-height: 500px;
      max-width: 640px;
   }

   .file-downloader-anchor{
      margin-top: 50px;
   }

}