.individual-student-view{


   .status-icon{
      width:22px;
   }

   .individual-student-view-header{

      .student-name{
         font-size: 25px;
         margin-left: 40px;
      }

      .year, .reg, .student-percentage-header{
         font-size: 20px;
         margin-left: 40px;
      }
      .student-percentage-header{
         margin-top:20px;
         width: 300px;
      }

      margin-bottom: 40px;
   }


   .filter-row{
      display: block;
      min-width: 660px;
      overflow: visible;
      word-wrap: none;
      white-space: nowrap;
   }

   .filters-label{
      width: 70px;
      text-align: right;
      padding: 14px 10px;
   }

   .filters-typeahead-wrapper-subject{
      display: inline-block;
      width: 350px;
   }

   .filters-title-search-box{
      display:inline-block;
      margin-right: 20px;
      margin-left: 24px;
   }


      /* IE9, IE10, IE11 */
   @media screen and (min-width:0\0) {
      .filters-title-search-box{
         margin-top: 6px;
      }
      .filter-drop{
         line-height: 29px!important;
      }
   }

   .filters-title-search-box-absolute-container{
      width:200px;
      display:inline-block;
      input{
         padding-left: 30px;
      }
   }

   .filters-title-search-box-absolute-container{
      position: relative;
   }

   .filters-title-search-box .search-icon {
      z-index: 100;
      position: absolute;
      left: 8px;
      top: 8px;
      color: #999;
   }

   .page-selector{
      margin-top: 0!important;
   }


   .student-homework-list {
      background-color: #e6e6e6;
      min-width: 788px;
      padding: 4px;
  
  
      .hand-in-status{
         position: absolute;
         right: 40px;
         top: 12px;

         .emphasise-text{
            margin-left:10px;
         }
         
         .faint-text{
            font-size:13px;
         }

         .mark-value{
            margin-right:30px;
         }

         .hand-in-status-text{
            width: 110px;
            display:inline-block;
         }
      }

      
     .homework-catelogue-list-item {
            background-color: #fff;
            margin-top: 2px;
            position: relative;
            padding: 10px 12px 6px 13px;
            height: 100px;
      }
       .homework-catelogue-list-item:first-child {
            margin-top: 0;
      }
      
      .student-homework-list-table {
            table-layout: fixed;
            width: 744px;
      }
      
      .student-homework-list-table td, #student-homework-list-table th {
            padding: 5px;
      }
      
      .student-homework-list-table tr {
            line-height: 17px;
      }
      
      .date-label-properties {
            text-align: right;
            font-size:13px;
      }
      .date-value {
            width:200px;
      }
      
      .faint-text {
            color: #808080;
      }
      
      .linked-entities {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
      }
      
      .linked-entity-properties {
            background: transparent;
            color: #4d4d4d;
            font-size: 13px;
            padding: 2px 4px;
      }
      
      .small-text {
            font-size: 9px;
      }
      
      .homework-title {
            max-width: 535px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            font-size: 20px;
            color: #1a1a1a;
            font-weight: normal;
            height:27px;
            margin-bottom: -3px;
            margin-left: 5px;
      }
      
      .emphasise-text {
            color: #333333;
            font-size: larger;
      }
      
      .label-homework-set-buy-container {
            display:inline-block;
            vertical-align: text-bottom;
      }
      
      .label-homework-set-buy {
            margin-left: 7px;
            font-weight: lighter;
      }
      
      .homework-set-buy {
            margin-left: 5px;
      }
      
      .homework-attachment {
            margin-right: 10px;
      }
      
      .homework-subject {
            width:198px;
            max-width: 178px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 15.5px;
      }
      
      .homework-available-from {
            width:120px;
      }
      
      .student-action-buttons { 
            text-align: right;
            position: absolute;
            bottom:0;
            right: 0px;
            padding: 8px;
            display: inline-flex;
      }
      
      .homework-actions-area .btn { 
         margin-left: 10px;
      }

      .handed-in-icon, .not-set-icon, .handed-in-late-icon, .not-handed-in-icon, .due-icon{
         margin-left:20px;
         font-size: 22px;
      }

      .handed-in-icon{
         color:darken(rgb(144, 237, 125), 20%)
      }

      .not-set-icon{
         color: darken(#A9A9A9, 20%)
      }

      .due-icon{
         color: darken(#7cb5ec, 20%)
      }

      .handed-in-late-icon{
         color:  darken(rgb(247, 163, 92), 20%)
      }

      .not-handed-in-icon{
         color:  darken(rgb(244, 91, 91), 20%)
      }

   }
   
}