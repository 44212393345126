.grid-container {
    display: block;
    height: 300px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.student-preview .react-grid-Cell:focus {
    outline: none;
 }

 .student-preview .react-grid-Main{
     outline: none;
 }

 .student-preview{
     position: relative;
 }

 .student-preview .student-preview-loady{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    text-align: center;
    background-color: #999;
    opacity: .3;
 }

 .student-preview .student-preview-loady-text{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    vertical-align: middle;
    margin-top: 123px;
    margin-left: 230px;
    font-size: 20px;
    opacity: 1;
    z-index: 2001;
 }

 .student-preview .react-grid-Cell__value div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  
  .student-preview .registered{
    position: relative;
    display:inline-block;
    font-size: 10px;
    margin-left: 10px;
    padding: 0 3px;
    background-color: #c7ecc7
  }

  .student-preview .not-registered{
    position: relative;
    display:inline-block;
    font-size: 10px;
    margin-left: 10px;
    padding: 0 3px;
    background-color: #fdc2c2
  }