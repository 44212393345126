
$min-width: 950px;
.dashboard-row-2{
   min-width: $min-width;
   .dashboard-row-item{
      width:50%;
      float: left;
      
   }

   .dashboard-row-item:first-child{
      padding-right: 10px;
   }

   .dashboard-row-item:last-child{
      padding-left: 10px;
   }
}

.dashboard-row-1{
   clear:left;
   padding-top: 15px;
   min-width: $min-width;
   .dashboard-row-item{
      width:100%;
      float: left;
   }
}