
.react-grid-Row{
    cursor: pointer;
}

.student-selector .student-selector-popout{
    background-color:#FAFAFA;
    border: solid 1px #ccc; 
    padding: 9px 17px;
    height: 382px;
    width: 517px;
    position: absolute;
    z-index: 1000;
    margin-top: -1px;
}

/*
    LISA: check for properties that are not supported in IE8
    opacity
    border-radius
    last-of-type
    first-of-type
*/
.student-selector-loady{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    text-align: center;
    background-color: #999;
    opacity: .5;
}
.student-selector-loady-text{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    vertical-align: middle;
    margin-top: 163px;
    margin-left: 180px;
    font-size: 20px;
    opacity: 1;
    z-index: 2001;
}


.student-selector .student-selector-clickable{

    padding: 2px 4px;
    cursor: pointer;
    user-select: none;

    border: solid 1px #28223D;
    background-color: #EaEaF9;
    color: #000;
}

.student-selector .student-selector-open-button{
    border: solid 1px #ccc;
    background-color: #fff;
    height: 34px;
    width: 40px;
    position: absolute;
    right: 15px;
    top: 0;
    border-radius: 0 3px 3px 0;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
}

.student-selector .student-selector-open-button:disabled{
    cursor: not-allowed;
    color: grey;
}

.student-selector .student-selector-preview{
    position: absolute;
    z-index: 1000;
    background-color:#FAFAFA;
    border: solid 1px #ccc; 
    padding: 9px 17px;
    max-width: 350px;
    max-height: 350px;
    overflow-y: auto;

}

.student-selector .student-selector-preview-item-cross{
    position: absolute;
    right: -4px;
    top: -6px;
    height: 12px;
    width: 12px;
    background-color: rgb(255, 6, 6);
    border-radius: 4px;
    cursor: pointer;
    font-size: 9px;
    color: #fff;
    text-align: center;
}

.student-selector .student-selector-preview-item{
    border: solid 1px #28223D;
    background-color: #EaEaF9;
    color: #000;
    padding: 2px 3px;
    margin: 3px;
    display: inline-block;
    position: relative;
}

.student-selector .student-selector-clickable:last-of-type{
    margin-left: 10px;
}
.student-selector .student-selector-clickable:first-of-type{
    margin-left: 0px!important;
}


.student-selector .no-students-selected{
    opacity: 0.4;
}

.student-selector .checkbox-align{
    margin-top: 5px!important;
}

.student-selector .student-selector-popout .student-selector-popout-top{
    padding-bottom: 11px;
}

.student-selector .student-selector-popout .student-selector-popout-mid{
    padding-bottom: 11px;
}

.student-selector .student-selector-popout .student-selector-popout-preview-container{
    text-align: right;
    font-size: 13px;
    padding-top: 7px;
}

.student-selector .student-selector-popout .student-selector-popout-button-container{
    margin-top: 6px;
}

.student-selector .student-selector-popout .student-selector-search-box{
    width:200px;
    position: relative;
    background-color:#fff;
    z-index: 1000;
    float:right;
}

.student-selector .student-selector-popout .student-selector-search-box input{
    background-color: transparent;
    padding-left:26px!important;
    border-color: #ccc !important;
}


.student-selector .student-selector-popout .search-icon{
    z-index: -2;
    position: absolute;
    left: 8px;
    top: 8px;
    color: #999;
}

.student-selector .react-grid-Canvas{
    overflow-x: hidden!important;
}


.student-selector-popout-grid-container{
    max-height:256px;
    font-size: 12px;
}

.student-selector-popout-grid-container .react-grid-Cell:focus {
    outline: none;
 }

 .student-selector-popout-grid-container .react-grid-Main{
     outline: none;
 }

 .student-selector .form-control.disabled {
    background-color: #E6E6E6!important;
    cursor: not-allowed;
 }

 .student-selector .react-grid-HeaderRow{
     overflow-y: hidden;
 }

 .student-selector .react-grid-HeaderRow .checkbox-align{
    margin-top: 0px!important;
}

 