
.attachment-container{
    background-color: #E6E6E6;
    min-height:100px;
    padding-bottom: 48px;
    text-align: center;
    position: relative;
    padding: 5px 0;
    padding-bottom: 49px;
}

.attachment-container-scroll-container{
    padding: 0 7px;
    max-height: 260px;
    overflow-y: auto;
}

.attachment-container .upload-attachment{
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.attachment-container-text{
    margin-top: 17px;
    display: inline-block;
    font-size: 18px;
    color: #999;
    
}

.dragActive .attachment-container {
    background-color: #dedede!important;
}