.sort-order-selector{
    background-color: #e6e6e6;
    padding: 4px 4px 3px 4px;
    display: inline-block;
    margin-bottom: -3px;
    height: 35px;
    overflow: hidden;
}

.sort-order-selector-drop-down-item{
    padding: 12px;
    cursor: pointer;
    user-select: none;
}

.sort-order-selector-drop-down-item:hover{
    background-color: #e4e4e4;
}

.sort-order-selector .sort-order-selector-header{
    background-color: #fff;
    padding: 4px;
    cursor: pointer;
    user-select: none;
}

.sort-order-selector .order-by{
    color: #808080;
    font-size: 13px;
}

.sort-order-selector .title{
    color: #222;
    margin-left: 6px;
}

.sort-order-selector .help-text{
    color: #808080;
    font-size: 9px;
    margin-left: 6px;
}

.sort-order-selector .sort-order-selector-drop-down{
    position: absolute;
    background-color: #fff;
    z-index: 1000;
    border: solid 1px #aaa;
}

.sort-order-selector .sort-order-selector-icon{
    margin-left: 6px;
}