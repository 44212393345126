.loady{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ccc;
    opacity: 0.5;
    z-index: 2000;;
}

.loady.opaque{
    opacity: 1;
}

.loady-text{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2001;
}

.loady-text .loady-middle{
    display: inline-block;
    margin-left:50%;
    margin-top:20%;
    opacity: 1;
    font-size: 25px;
}