.link-toolbar-button-popout {
    background-color:#FAFAFA;
    border: solid 1px #ccc; 
    padding: 10px;
    height: 140px;
    width: 200px;
    position: absolute;
    z-index: 1000;
    margin-top: -1px;
}

.margin-right {
    margin-right: 5px;
}

.link-toolbar-btn {
    border: 0px;
    border-right: 1px solid #ddd;
    color: #333;
    background-color: #f5f5f5;
    cursor: pointer;
    padding: 6px;
}

.link-toolbar-btn:hover {
    background-color: #eee;
}

.link-toolbar-btn-label {
    margin: 0px 6px;
}

.link-toolbar-button-popout .link-toolbar-button-popout-input {
    background-color: #fff;
    padding-left: 26px;
    border-color: #ccc!important;
}

.link-toolbar-button-popout .control-label{
    color: #333!important;
}