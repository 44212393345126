@import '../../variables.scss';

#handed-in-view{
   height: 100%;

   .handed-in-view-tabs{

      a{
         margin-top: -4px;
         color: #333;

         .handed-in-view-tab{
            display: inline-block;
            background-color: $inactiveTab;
            margin-right: 5px;
            padding: 6px;
            border: solid 1px $controlGrey;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: -5px;

            .file-icon{
               margin-right: 5px;
               margin-left: 3px;
            }
         }

         .handed-in-view-tab.active{
            background-color: $activeTab!important;
            border-bottom: solid 1px $activeTab;
         }
      }
   }

   .handed-in-view-body{
      background: $activeTab;
      padding: 10px;
   }
 
   .embedded-pdf{
      position: relative;
      z-index:1;
   }

}