.dropster{
    vertical-align: middle;
    background-color: #e6e6e6;
    display: inline-block;
    margin-bottom: -3px;
    line-height: 23px;
    font-size: 15px!important;
    margin-left: 15px;
    position: relative;
}

.dropster .dropster-header{
    background-color: #fff;
    cursor: pointer;
    user-select: none;
    padding-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropster-drop-down-item{
    padding: 12px;
    cursor: pointer;
    user-select: none;
    min-width: 200px;
}

.dropster-drop-down-item-child{
    padding-left: 25px;
    background-color: #fafafa;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropster-drop-down-item:hover{
    background-color: #e4e4e4;
}

.dropster .order-by{
    color: #808080;
    font-size: 13px;
    margin-left: 4px;
}
@media (max-width: 1500px) {
    .dropster .order-by{
        display: none;
    }
}

.dropster .title{
    color: #222;
    margin-left: 6px;
}

.dropster .help-text{
    color: #808080;
    font-size: 12px;
    margin-left: 6px;
    display: block;
}

.dropster .dropster-drop-down{
    position: absolute;
    background-color: #fff;
    z-index: 1000;
    border: solid 1px #aaa;
    right:0;
    max-height: 300px;
    overflow-y: auto;
    
}

.dropster .dropster-icon{
    margin-left: 12px;
}