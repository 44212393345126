@font-face {
	font-family: "sos-font";
	src: url("sos-font.eot?ae16f986d243107609e4775cda84bcf1?#iefix") format("embedded-opentype"),
url("sos-font.woff?ae16f986d243107609e4775cda84bcf1") format("woff"),
url("sos-font.ttf?ae16f986d243107609e4775cda84bcf1") format("truetype"),
url("sos-font.svg?ae16f986d243107609e4775cda84bcf1#sos-font") format("svg");
}

.icon {

	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;}

.icon:before {
	font-family: sos-font !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-dcs-pending:before {
	content: "\f101";
}
.icon-queue-eater:before {
	content: "\f102";
}
.icon-passport:before {
	content: "\f103";
}
.icon-parent:before {
	content: "\f104";
}
.icon-student:before {
	content: "\f105";
}
.icon-activities:before {
	content: "\f106";
}

.icon-magic-wand:before {
	content: "\f107";
}

.icon-not-handed-in:before {
	content: "\f108";
}

