@import '../../variables.scss';


#homework-teacher-view-student-list{
   .empty-list{
      font-size:20px;
      background-color: #fff;
      height: 100%;
      vertical-align: middle;
      text-align: center;
      padding: 30px;
      color: #888;
      .fa{
         margin-top:20px;
         font-size:40px;
      }
   }

};