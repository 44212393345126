.wysiwyg-editor{
 
    border: solid 1px #ccc;
    border-radius: 3px;
}

.wysiwyg-buttons{
    background-color:#fbfbfb;;
}

.wysiwyg-text-area{
    background-color:#fff;
    height: 180px;
    overflow:auto;
}

.wysiwyg-editor .draftJsToolbar__toolbar__dNtBH{
    border: none!important;
    border-bottom: 1px solid #ddd!important;
}

.wysiwyg-editor  .draftJsToolbar__toolbar__dNtBH{
    background-color:#fbfbfb;
}