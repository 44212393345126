.attachment-info{
    background-color: #fff;
    padding: 5px;
    display: table;
    width: 100%;
    margin: 2px 0;
    position: relative;
    text-align: left;
}

.attachment-info-file-size{
    position: absolute;
    right: 45px;
    bottom: 8px;
}

.attachment-info input{
    border: none!important;
    outline: none!important;
    box-shadow: none!important;
    display: inline-block;
    border-bottom: solid 1px #eee!important;
    border-radius: 0px!important;
}

.attachment-close{
    position: absolute;
    right: 1px;
    top: 1px;
    font-size: 20px;
    height: 31px;
    width: 30px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: #999;
}

.attachment-close:hover{
    color: #666;
}

.attachement-editable{
    color:#ddd;
}

.attachement-icon-row{
    display:table-row;
}

.attachment-info-form{
    display:table-cell;
    vertical-align: top;
    padding: 3px;
}

.attachement-icon{
    display:table-cell;
    vertical-align: top;
    width: 77px;
    position: relative;
}


.attachement-icon img{
    margin-top:2px;
    margin-left: 3px;
}

.attachement-icon-percentage-text{
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    color: #000;
    text-shadow: 1px -1px 7px #fff, 1px -1px 17px #fff;
}


.attachement-icon-percentage-text span{
    font-size: 22px;
}

.attachement-icon-percentage-overlay{
    position: absolute;
    background-color: #555;
    opacity: 0.3;
    top:0;
    right:0;
    bottom: 0;
    left:0;
}

input.attachment-title{
    max-width:200px;
    font-size: 17px;
    font-weight: bold;
}

input.attachment-description{
    max-width:250px;
}

