#homework-handed-in .alert{
    margin-bottom: -5px;
    margin-top: 7px;
}


#homework-handed-in #homework-handed-in-body {
    display: block;
}

#homework-handed-in .handed-in-status-td{
    text-align: right;
    width: 150px;
}

#homework-handed-in .handed-in-status {
   font-size: 13px;
   text-align: center;
   padding-right: 3px;
   padding-left: 3px;
   margin-right: 4px;
   background: transparent;
   display: inline-block;
}

#homework-handed-in .handed-in-icon {
    color: #085394;
}

#homework-handed-in .still-waiting-icon {
    color: #ff9900;
}

#homework-handed-in .faint-text{
    color:#808080;
}

#homework-handed-in .date{
    font-size:16px;
}

#homework-handed-in .subject{
    font-size:15.5px;
}

#homework-handed-in .overdue-icon {
    color: #cf2a27;
}

#homework-handed-in .handed-in-late-icon {
    color: #660000;
}

#homework-handed-in .submitted-on-line-icon {
    color: #2b78e4;
}

#homework-handed-in .marked-icon {
    color: #009e0f;
}

#homework-handed-in .info-icon {
    color: cornflowerblue;
    height: 25px;
}

#homework-handed-in .dog-ate-it-icon {
    color: burlywood
}

#homework-handed-in .legend-icon {
    width: 20px;
}

#homework-handed-in .status-icon {
    margin-right: 5px;
    margin-bottom: 1px;
}

#homework-handed-in #info-drop-down {
    position: absolute;
    background-color: #fff;
    z-index: 1000;
    border: solid 1px darkgray;
    border-radius: 4px;
    font-size: smaller;
    width: 170px;
    padding: 10px;
}

#homework-handed-in .info-drop-down-item {
    margin: 5px;
}

#homework-handed-in .homework-student-list-item .student-details {
    width: 215px;
    padding: 5px;
    text-align: left;
    
}

#homework-handed-in .homework-student-list-item {
    border: solid 4px #e6e6e6;
    cursor: pointer;
    margin: 0px;
    padding: 2px;
    border-top-width: 0px;
    border-left-width: 0px;
    background-color: #fff
}

#homework-handed-in .homework-student-list-item-checkbox-container{
    height: 65px;
    width:30px;
}

#homework-handed-in .homework-student-list-item-checkbox-container input{
    margin-top: 10px;
    margin-left: 8px;
}

#homework-handed-in .homework-student-list-item-table-container{
    display: inline-block;
    margin-left: 10px;
}

#homework-handed-in .homework-student-list-item table {
    width: 100%;
}

#homework-handed-in .homework-student-list-item .student-icon{
    padding: 12px;
}

#homework-handed-in .homework-student-list-item .student-icon svg{
    height:40px;
    width:40px;
    padding:5px 5px 5px 0;
    color: #CfCfCf;
}

#homework-handed-in .homework-student-list-item .student-icon img{
    height:40px;
    width:40px;
}

#homework-handed-in .homework-student-list-item:first-child {
    margin-top: 0;
}

#homework-handed-in .homework-student-list-item:last-child {
    border-bottom-width: 0;
}


#homework-handed-in .student-item-not-selected {
    background-color: white;
}

#homework-handed-in #change-status-button-group {
    vertical-align: middle;
}

#homework-handed-in #homework-student-list {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #e6e6e6;
    max-width: 525px;
    display: inline-block;
    border: solid 4px #e6e6e6;
}

#homework-handed-in #homework-handed-in-header .btn-toolbar {
    margin-bottom: 15px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}

#homework-handed-in #status-legend {
    width: 150px;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
}

#homework-handed-in .legend-text {
    font-size: inherit;
}

#homework-handed-in input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  padding: 10px;
}

#homework-handed-in .handedin-list-header{
    margin-bottom: -5px;
}

#homework-handed-in .homework-handed-in-actions{
    display: inline-block;
    vertical-align: top;
    width: 229px;
    margin-left: 15px;
}

#homework-handed-in .handed-in-status-update-btn {
    width: 100%;
    margin-top: 3px;
}

#homework-handed-in .homework-date {
    position: relative;
    float: right;
}

#homework-handed-in .homework-date .input-group {
    width: 229px;
}

#homework-handed-in .handed-in-date-label{
    font-weight: normal;
    font-size: 13px;
    margin-bottom: -4px;
}

#homework-handed-in .no-matching-students{
    padding: 100px 0;
    background-color: #eee;
    max-width: 750px;
    text-align: center;
}

#homework-handed-in .sort-order-selector{
    /* margin-bottom: -4px;
    margin-left: -2px;
    padding: 2px 2px 1px 0px;
    background: transparent; */
}

#homework-handed-in .filters-label{
    height: 30px;
    vertical-align: middle;
}


