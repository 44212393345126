#grade-and-comment{
   background-color: #fff;
   height: 100%;
   width: 100%;

   text-align: left;
   padding: 20px;

   .grade-label{
      display: inline-block;
      font-size: 30px;
      color:#777;
      width: 120px;
      margin-bottom: 30px;
   }
   
   .grade{
      font-size: 40px;
      margin-left: 50px;
      color:#222;
   }

   .comment-label{
     /* margin-top: 60px; */
     display: inline-block;
     font-size: 30px;
     color: #777;
     width: 120px;
     vertical-align: top;
   }
   
   .comment{
      font-size: 40px;
      margin-top: -8px;
      margin-left: 50px;
      color: #222;
      display: inline-block;
      max-width: 800px;
   }
}