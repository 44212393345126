.student-percentage-tooltip{
   background-color: #fff;
   position: absolute;
   top: -1000px;
   left: -1000px;
   z-index: 20000;
   opacity: .9;
   border-radius: 2px;
   padding: 10px 15px;
   font-size: 14px;
}