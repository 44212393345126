@import '../variables.scss';


#homework-mark-actions{

  width:500px;
  position: absolute;
  background-color: #fff;
  border: solid 1px #aaa;
  border-radius: 4px;
  padding: 20px 20px 15px 20px;
  right:7px;
  border-bottom-left-radius: 4px;
  z-index: 10000;

  #homework-comment-textarea{
    width: 100%;
  }

  .homework-comment,.homework-grade{
    margin-bottom: 5px;
  }

  .save-button{
    margin-top:15px;
  }

  .homework-mark-actions-bar{
    height: 40px;
    background-color: $controlGrey;
    padding: 4px;
 
    background: repeating-linear-gradient(
   45deg,
   #606dbc,
   #606dbc 10px,
   #465298 10px,
   #465298 20px
 );
 }

 .cover {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}