.home-work-select-all{
    background: transparent;
    padding: 2px 2px 2px 2px;
    display: inline-block;
    margin-bottom: -3px;
    height: 35px;
    overflow: hidden;
    border: solid 4px #e6e6e6;
}

.home-work-select-all-inner{
    background-color: #fff;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 13px;
    height: 28px;
    width: 28px;
}

.home-work-select-all-inner input {
    vertical-align: top;
}