@import '../variables.scss';


$studentActive:#FFA359;


#homework-teacher-view-student-list{
  background-color: $controlGrey;
  position: relative;  
  min-width: 285px;

  .tabs{

      white-space: nowrap;

      a{
         color:inherit!important;
         text-decoration: none!important;
      }

      .tab{
         position: relative;
         padding: 8px 8px;
         background: $inactiveTab;
         display: inline-block;
         border: solid 4px $controlGrey ;
         border-left: solid 6px $controlGrey;
         font-size: 12px;
         font-weight: 600;

         .num{
            position: absolute;
            top: -8px;
            right: -4px;
            background-color: #d6e3ff;
            padding: 0px 4px;
            border-radius: 11px;
            border: solid 1px #bacaec;
            z-index: 100;
         }
      }

      .tab.active{
         background: $activeTab;
         display: inline-block;
         border: solid 4px $activeTab;
         border-bottom: solid 6px $activeTab;
         margin-bottom: -2px;
      }
   }

   .student-list-refresh{
      display: block;
      height: 20px;
      width: 20px;
      background-color: #fff;
      position: absolute;
      right:2px;
      top: 0;
      vertical-align: middle;
      text-align: center;
      border-radius: 3px;
   }

   .students{
      background-color:#fff;
      padding: 4px;
      overflow-y: auto;
      border-right: solid 3px $controlGrey;
      
      a.student-item-anchor{
         color:inherit!important;
         text-decoration: none!important;
      }

      .student-item{
         position: relative;
         height:50px;
         background-color:#fff;
         margin-top: 2px;
         padding: 4px 6px 4px 10px;
         cursor: pointer;

         border: solid 1px $controlGrey;

         .student-item-name{
            display: inline-block;
            font-size:14px;
            font-weight: 500;
            max-width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
         }
         .student-item-status{
            margin-top:0;
            display: block;
            font-size:10px;
         }
         .student-item-year{
            position: absolute;
            right: 10px;
            top: 8px;
            font-size:10px;
         }

         .active-arrow{
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 0 15px 7.5px;
            border-color: transparent transparent transparent $studentActive;
            position: absolute;
            left: 0;
            top: 8px;
         }
      }
      .student-item.active{
         border-color:$studentActive;
      }
   }
};