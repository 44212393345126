
@import '../variables.scss';

#homework-header-actions{
   height: 40px;
   background-color: $controlGrey;
   display: table;
   width: 100%;
   .homework-header-actions-row{
      display: table-row;
      .homework-header-actions-row-cell{
         display: table-cell;
      }
      .cell-left{
         text-align: left;
      }
      .cell-center{
         text-align: center;
      }
      .cell-right{
         text-align: right;
      }

      .action-button{
         margin-right:10px!important;
      }
      .action-button:last-of-type{
         margin-right:0;
      }
   }
}