
.homework-create {
    padding-top:20px;
}

.right {
    float: right
}

.left {
    float: left
}

.panel-info  {
    border-color: #dcccfa !important;
}

.panel-info > .panel-heading {
    background-color: #e4e1f1 !important;
    border-color: #dcccfa !important;
    color: #463C6B !important;
}