.student-percentage:hover .bar{
   opacity: .5;
}
.student-percentage{
   width:100%;
   background-color:pink;
   background-color: #ddd;
   height: 20px;
   border-radius: 5px;
   overflow: hidden;

   .bar{
      height: 20px;
      width:0;
      display: block;
      float: left;
      cursor: pointer;
   }

   .bar:hover{
      opacity: 1;
   }

   .sp-handed-in{
      background-color: rgb(144, 237, 125)
   }
   .sp-handed-late{
      background-color:  rgb(247, 163, 92);
   }
   .sp-handed-current{
      background-color: #7cb5ec;
   }
   .sp-handed-not-handed-in{
      background-color:  rgb(244, 91, 91);
   }
}