
.messages{
    padding-top:20px;
}

.messages .col-sm-3{
    min-width: 319px
}

.messages .message-table{
    display:table; 
    min-width: 677px;
}

.messages .message-table-raw{
    display:table-row; 
	width: 100%;
}
.messages .message-padding{
    padding: 0;
}

.messages .message-table-cell{
	display: table-cell;
    width: 75%; 
}

.messages .message-textarea{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    resize: none;
    min-height: 300px !important;
    border-radius: 0px !important
}

.messages .message-bottombox{
    border-top: 1px solid #ccc;
    padding-bottom: 5px; 
}

.messages .message-topbox{
    padding-bottom: 5px; 
}

.messages .message-table-cell-tags{
	display: table-cell; 
	background-color: #E6E6E6;
	border-radius: 0px 0px 0px 0px;
    font-family: Calibri;
    border: 1px solid #ccc;    
    border-left: 0px;    
    padding: 4px;
    vertical-align: top;    
    min-height: 300px !important;
}

.messages .message-tag{
    margin: 2px;
    background-color:white;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 3px 5px !important;
    display: inline-block;
    cursor: pointer;
}

.messages .message-tag-label{
    margin: 3px;
    color: gray;
    font-size: 12px;
}

.message-preview-link-align {
    margin-left:14px
}

.messages .message-clear-both{
    clear: 'both'
}
.messages .save-button-container{
    text-align: right;
    min-width: 677px!important;
}
.messages .attachment-table{
    width: 100% !important;
    display:table; 
}
.messages .toolbar {
    border: 1px solid #ddd;
    border-bottom: 0px;
    color: #333;
    background-color: #f5f5f5;
    min-width: 677px;
}
 @media screen and (max-width:1480px) {
    .messages .attachment-padding{
        left: -15px !important
    }
    @media screen and (max-width:841px) {
        .messages .attachment-padding{
            left: 0 !important
        }
     }
 }