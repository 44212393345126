#homework-filters{
    padding: 20px 20px 20px 0; 
}

#homework-filters #homework-title-search-box {
    width:200px;
    position: relative;
    background-color:#fff;
    z-index: 1000;
    float:right;
}


#homework-filters .filter-row{
    display: block;
    min-width: 660px;
    overflow: visible;
    word-wrap: none;
    white-space: nowrap;
}

#homework-filters .filters-label{
    width: 70px;
    text-align: right;
    padding: 14px 10px;
}

#homework-filters .filters-show{
    display: inline-block;
    width: 425px;
}

#homework-filters .filters-set-by{
    display: inline-block;
    margin-bottom: -1px;
}

#homework-filters .filters-set-by-typeahead-wrapper{
    width: 235px;
    vertical-align: middle;
}

#homework-filters .filters-typeahead-wrapper{
    display: inline-block;
    width: 227px;
}

#homework-filters .filters-title-search-box{
    width: 150px;
    display: inline-block;
    margin-right: -20px;
    margin-top: 7px;
    margin-left: 10px;
}

#homework-filters .filters-title-search-box input{
    padding-left: 30px;
}

#homework-filters .filters-title-search-box-absolute-container{
    position: relative;
}

#homework-filters .filters-title-search-box .search-icon {
    z-index: 100;
    position: absolute;
    left: 8px;
    top: 8px;
    color: #999;
}

#homework-filters .filters-subject{
    display: inline-block;
    width: 425px;
}

#homework-filters .filters-assigned-to{
    display: inline-block;
}

#homework-filters .student-selector-absolute-container{
    display: inline-block;
    position: relative;
    width: 192px;
}

#homework-filters .student-selector .student-selector-open-button{
    right: -36px;
}

#homework-filters .student-selector .form-control {
    padding-left: 5px;
    padding-right: 5px;
}

#homework-filters .filters-typeahead-wrapper-subject{
    display: inline-block;
    width: 306px;
}

#homework-filters .right-search-filter{
    vertical-align: top;
    text-align: right;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .filters-typeahead-wrapper-subject{
        margin-bottom: -12px;
    }

}