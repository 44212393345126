.custom-date-picker-input {
    cursor: pointer;
    background: #fff;
}

.custom-date-picker-input:read-only {
    background: #fff!important;
}

.custom-date-picker-input:disabled {
    background: #E6E6E6!important;
}