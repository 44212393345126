.student-view-student-selector{
   width: 207px;
   position: absolute;
   right: 20px;
   top: 8px;
   
   .student-selector-open-button{
      right: 0;
   }

   .student-selector .student-selector-popout {
      font-size: 14px;
      line-height: 1.42857143;
      right: 0;
      height:auto;
   }
}

.student-widget{
   .dropster .order-by{
      display: inline-block!important;
   }

   .time-frame{
      margin-right:250px;
   }

   .widget{
      border:none;
      .widget-header{
         border: solid 1px #ccc;
      }

      .widget-content.empty-list{
         border-left: solid 1px #ccc;
         border-right: solid 1px #ccc;
         border-bottom: solid 1px #ccc;
         min-height: 350px;
      }

      .page-selector{
         margin-top: -16px;
      }
   }
}
