#homework-list {
    background-color: #e6e6e6;
    min-width: 788px;
    padding: 4px;
}

.homework-catelogue-controls-container{
    max-width: 1000px;
}

#homework-list .homework-catelogue-list-item {
    background-color: #fff;
    margin-top: 2px;
    position: relative;
    padding: 10px 12px 6px 13px;
    height: 100px;
}
#homework-list .homework-catelogue-list-item:first-child {
    margin-top: 0;
}

#homework-list #homework-list-table {
    table-layout: fixed;
    width: 744px;
}

#homework-list #homework-list-table td, #homework-list-table th {
    padding: 5px;
}

#homework-list #homework-list-table tr {
    line-height: 17px;
}

#homework-list .date-label-properties {
    text-align: right;
    font-size:13px;
}
#homework-list .date-value {
    width:200px;
}

#homework-list .faint-text {
    color: #808080;
}

#homework-list .linked-entities {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#homework-list .linked-entity-properties {
    background: transparent;
    color: #4d4d4d;
    font-size: 13px;
    padding: 2px 4px;
}

#homework-list .small-text {
    font-size: 9px;
}

#homework-list .homework-title {
    max-width: 535px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    font-size: 20px;
    color: #1a1a1a;
    font-weight: normal;
    height:27px;
    margin-bottom: -3px;
    margin-left: 5px;
}

#homework-list .emphasise-text {
    color: #333333;
    font-size: larger;
}

#homework-list .label-homework-set-buy-container {
    display:inline-block;
    vertical-align: text-bottom;
}

#homework-list .label-homework-set-buy {
    margin-left: 7px;
    font-weight: lighter;
}

#homework-list .homework-set-buy {
    margin-left: 5px;
}

#homework-list .homework-attachment {
    margin-right: 10px;
}

#homework-list .homework-subject {
    width:198px;
    max-width: 178px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15.5px;
}

#homework-list .homework-available-from {
    width:120px;
}

#homework-list .homework-actions-area { 
    /* width: 252px; */
    text-align: right;
    position: absolute;
    bottom:0;
    right: 0px;
    padding: 8px;
    display: inline-flex;
}

#homework-list .homework-actions-area .btn { 
  margin-left: 10px;
}

#homework-catelogue .drop-down-selector {
    width: 200px;
}

#homework-catelogue .no-records{
    background-color: #eee;
    padding: 100px 0;
}

#homework-catelogue .sort-order-selector{
    margin-bottom: -8px;
}


#homework-catelogue .homework-stats {
    position: absolute;
    right: 8px;
    bottom: 70px;
}