@charset "UTF-8";
/* Space out content a bit */
/* line 14, ../../app/styles/main.scss */
body {
  padding-top: 50px;
  padding-bottom: 20px;
}

/* line 19, ../../app/styles/main.scss */
.modal-open {
  overflow-y: auto;
}

/* Custom page header */
/* line 24, ../../app/styles/main.scss */
.header {
  border-bottom: 1px solid #e5e5e5;
  /* Make the masthead heading the same height as the navigation */
}
/* line 27, ../../app/styles/main.scss */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

/* Custom page footer */
/* line 36, ../../app/styles/main.scss */

.container-height {
  min-height: 80vh;
}

.footer {
  text-align: center;
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

/* line 43, ../../app/styles/main.scss */
.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
/* line 48, ../../app/styles/main.scss */
.jumbotron {
  /*text-align: center;*/
  border-bottom: 1px solid #e5e5e5;
}
/* line 52, ../../app/styles/main.scss */
.jumbotron .btn {
  font-size: 21px;
  padding: 7px 12px;
}

/* Supporting marketing content */
/* line 59, ../../app/styles/main.scss */
.marketing {
  margin: 40px 0;
}
/* line 62, ../../app/styles/main.scss */
.marketing p + h4 {
  margin-top: 28px;
}

/* Layout */
/* line 69, ../../app/styles/main.scss */
.vertical-spacing {
  margin-top: 1em;
}

/* line 73, ../../app/styles/main.scss */
.horizontal-spacing {
  margin-right: 0.5em;
}

/* line 77, ../../app/styles/main.scss */
.grow-to-fit {
  width: 100%;
}

/* Messages */
/* line 83, ../../app/styles/main.scss */
.subtle-info {
  font-style: italic;
}

/* Forms and validation */
/* line 89, ../../app/styles/main.scss */
h3.block-title {
  padding-top: 0px;
  margin-top: 0px;
}

/* line 94, ../../app/styles/main.scss */
.panel-heading div {
  font-weight: bold;
}

/* line 98, ../../app/styles/main.scss */
.form-group .help-block {
  display: none;
}

/* line 102, ../../app/styles/main.scss */
.form-group.has-error .help-block {
  display: block;
}

/* line 106, ../../app/styles/main.scss */
.form-feedback-required {
  color: lightgrey;
}

/* line 110, ../../app/styles/main.scss */
.server-error {
  color: #a94442;
}

/* line 114, ../../app/styles/main.scss */
.server-success {
  color: #3c763d;
}

/* line 118, ../../app/styles/main.scss */
.strikeout-error {
  color: #a94442;
  text-decoration: line-through;
}

/* line 123, ../../app/styles/main.scss */
.invite-code {
  color: red;
  font-weight: strong;
  font-size: larger;
  padding: 0 2em 0 2em;
  text-align: center;
}

/* Grids */
/* line 133, ../../app/styles/main.scss */
.ec-small-grid {
  height: 12em;
}

/* line 137, ../../app/styles/main.scss */
.ec-medium-grid {
  height: 24em;
}

/* line 141, ../../app/styles/main.scss */
.ec-modal-grid {
  width: 866px;
  height: 12em;
}

/* line 147, ../../app/styles/main.scss */
.nav-pills-sm > li {
  float: left;
}
/* line 150, ../../app/styles/main.scss */
.nav-pills-sm > li > a {
  border-radius: 4px;
}
/* line 154, ../../app/styles/main.scss */
.nav-pills-sm > li + li {
  margin-left: 2px;
}
/* line 159, ../../app/styles/main.scss */
.nav-pills-sm > li.active > a, .nav-pills-sm > li.active > a:hover, .nav-pills-sm > li.active > a:focus {
  color: #fff;
  background-color: #337ab7;
}

/* line 169, ../../app/styles/main.scss */
.nav-sm {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
/* line 14, ../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.nav-sm:before, .nav-sm:after {
  content: " ";
  display: table;
}
/* line 19, ../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.nav-sm:after {
  clear: both;
}
/* line 175, ../../app/styles/main.scss */
.nav-sm > li {
  position: relative;
  display: block;
}
/* line 179, ../../app/styles/main.scss */
.nav-sm > li > a {
  position: relative;
  display: block;
  padding: 4px;
}
/* line 184, ../../app/styles/main.scss */
.nav-sm > li > a:hover, .nav-sm > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
/* line 191, ../../app/styles/main.scss */
.nav-sm > li.disabled > a {
  color: #777777;
}
/* line 194, ../../app/styles/main.scss */
.nav-sm > li.disabled > a:hover, .nav-sm > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}
/* line 205, ../../app/styles/main.scss */
.nav-sm .open > a, .nav-sm .open > a:hover, .nav-sm .open > a:focus {
  background-color: #eeeeee;
  border-color: #337ab7;
}
/* line 217, ../../app/styles/main.scss */
.nav-sm .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
/* line 223, ../../app/styles/main.scss */
.nav-sm > li > a > img {
  max-width: none;
}

/* line 228, ../../app/styles/main.scss */
.skip-onboarding-button {
  margin-right: 10px;
}

/* line 238, ../../app/styles/main.scss */
.btn-mobile-header {
  background-color: #222;
  border-color: white;
  color: white;
}

/* line 245, ../../app/styles/main.scss */
.mobile-row-stripe {
  background-color: #f9f9f9;
}

/* line 249, ../../app/styles/main.scss */
.btn-mobile {
  width: 100%;
}

/* line 253, ../../app/styles/main.scss */
.btn-signin {
  width: 100%;
  background-origin: content-box;
  background-position: left center;
  background-repeat: no-repeat;
  color: #ffffff;
  text-align: right;
}

/* line 262, ../../app/styles/main.scss */
.btn-signin span {
  margin: 0 0 0 40px;
}

/* line 266, ../../app/styles/main.scss */
ul.signin-provider {
  width: 100%;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0px;
}

/* line 273, ../../app/styles/main.scss */
ul.signin-provider li {
  margin-bottom: 10px;
}

/* line 277, ../../app/styles/main.scss */
.btn.Google {
  background-color: #E2492A;
  /* background-image: url(../images/GoogleIcon.4a3fbd06.png); */
}

/* line 282, ../../app/styles/main.scss */
.btn.Microsoft {
  background-color: #0170C9;
  /* background-image: url(../images/WindowsIcon.ebca3539.png); */
}

/* line 287, ../../app/styles/main.scss */
.btn.OpenIdConnect {
  background-color: #EE3900;
  /* background-image: url(../images/Office365Icon.514a121b.png); */
}

/* line 292, ../../app/styles/main.scss */
.onboarding-header {
  padding-left: 1em;
}

/* Toggle Styles */
/* line 300, ../../app/styles/main.scss */
#wrapper {
  padding-left: 50px;
  transition: all 0.5s ease;
}

/* line 308, ../../app/styles/main.scss */
#wrapper .sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 0px;
  width: 50px;
  height: 100%;
  margin-left: 0px;
  overflow-y: auto;
  background-color: #222;
  transition: all 0.5s ease;
}

/* line 323, ../../app/styles/main.scss */
#wrapper.sidebar-untoggled .sidebar-wrapper {
  width: 50px;
}

/* line 327, ../../app/styles/main.scss */
#wrapper.sidebar-toggled .sidebar-wrapper {
  width: 250px;
}

/* line 331, ../../app/styles/main.scss */
#wrapper .page-content {
  width: 100%;
  position: relative;
  padding: 15px;
}

/* line 337, ../../app/styles/main.scss */
.col-xs-14,
.col-sm-14,
.col-md-14,
.col-lg-14 {
  position: relative;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
}

/* line 347, ../../app/styles/main.scss */
.col-xs-14 {
  width: 14%;
  float: left;
}

@media (min-width: 768px) {
  /* line 353, ../../app/styles/main.scss */
  .col-sm-14 {
    width: 14%;
    float: left;
  }
}
@media (min-width: 992px) {
  /* line 360, ../../app/styles/main.scss */
  .col-md-14 {
    width: 14%;
    float: left;
  }
}
@media (min-width: 1200px) {
  /* line 367, ../../app/styles/main.scss */
  .col-lg-14 {
    width: 14%;
    float: left;
  }
}
/* line 373, ../../app/styles/main.scss */
.today {
  background-color: rgba(254, 202, 16, 0.5);
  font-weight: strong;
}

/* line 379, ../../app/styles/main.scss */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/* line 385, ../../app/styles/main.scss */
.container {
  width: 960px;
  margin-top: 20px;
  margin-left: 0;
  margin-right: 0;
}

/* line 392, ../../app/styles/main.scss */
.page-content {
  margin-left: 225px;
  padding: 35px 25px;

}

.page-content-center{
  padding: 10px 7px;
}

@media (max-width: 991px) {
  /* line 397, ../../app/styles/main.scss */
  .page-content {
    margin-left: 60px;
  }
}
/* line 402, ../../app/styles/main.scss */
.mainview {
  padding: 20px;
}

/* line 406, ../../app/styles/main.scss */
input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.8);
  /* IE */
  -moz-transform: scale(1.8);
  /* FF */
  -webkit-transform: scale(1.8);
  /* Safari and Chrome */
  -o-transform: scale(1.8);
  /* Opera */
  padding: 10px;
}

/* line 1, ../../app/styles/Imports/_navbar.scss */
.breadcrumbs {
  font-size: 16px;
  margin-left: 15px;
  padding-top: 2px;
}
/* line 6, ../../app/styles/Imports/_navbar.scss */
.breadcrumbs .crumb-text {
  background-color: #ddd;
  padding: 10px 15px;
  margin: 0 15px;
  font-size: 17px;
}
/* line 13, ../../app/styles/Imports/_navbar.scss */
.breadcrumbs .breadcrumb-angle {
  color: #888;
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -6px;
}

/* line 22, ../../app/styles/Imports/_navbar.scss */


.dropdown-menu {
  min-width: 205px;
  padding: 0 !important;
  background-color: #fff !important;
  background: linear-gradient(#eee, #fff );
  border: 1px solid #ccc !important;
  margin: 0 !important;
  left: auto;
  right: 0 !important;
  display: block!important;
  width: 100%
}

.dropdown-menu .nav-user-dropdown-picture{
  padding-top: 10px;
  padding-left: 15px;
}

.dropdown-menu .nav-user-dropdown-picture > .nav-user-dropdown-picture-img{
  height: 147px;
  width: 111px;
}

.dropdown-menu .nav-user-dropdown-name {
  padding: 10px 0px 0px 30px;
}

.dropdown-menu .signInOut > div {
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
}

.dropdown-menu .signInOut div:hover {
  background-color: #eee;
}

.dropdown-menu .signInOut i {
  margin-right: 10px;
  width: 15px;
  font-size: 14px;
}

.dropdown-menu .signInOut {
  padding-bottom: 5px;
}

/* line 54, ../../app/styles/Imports/_navbar.scss */
.dropdown-menu .nav-user-idp {
  text-align: center;
  padding-top: 9px;
}

.dropdown-menu .nav-user-header {
  font-size: 12px;
  display: block;
  margin-left: 15px;
  padding-top: 10px;
}
.dropdown-menu .nav-user-info {
  font-size: 12px;
  display: block;
  font-weight: bold;
  margin-left: 15px;
}

.nav-user .dropdown-menu .divider {
  border-color: #444;
  height: 1px;
  padding: 0px;
  margin: 5px;
}

/* line 66, ../../app/styles/Imports/_navbar.scss */
.navbar-nav {
  margin: 0;
}
/* line 69, ../../app/styles/Imports/_navbar.scss */
.navbar-nav .nav-user-name {
  padding: 15px 45px 15px 20px;
  display: inline-block;
  height: 100%;
}

.nav-user .user-mini-photo{
  margin-right:10px;
  height: 48px;
  padding: 4px 0px;
}

/* line 75, ../../app/styles/Imports/_navbar.scss */
.navbar-nav .user-image i {
  padding: 4px 15px 0 5px;
  font-size: 40px;
}

/* line 82, ../../app/styles/Imports/_navbar.scss */
.mobile-navbar {
  background-color: #f8f8f8;
  position: fixed;
  min-height: 50px;
  margin-bottom: 20px;
  top: 0px;
  right: 0px;
  width: 100%;
  z-index: 1030;
  left: 0;
  min-width: 1000px;
  height: 80px;
}
/* line 14, ../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.mobile-navbar:before, .mobile-navbar:after {
  content: " ";
  display: table;
}
/* line 19, ../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.mobile-navbar:after {
  clear: both;
}
/* line 95, ../../app/styles/Imports/_navbar.scss */
.mobile-navbar .mobile-navbar-title {
  color: #fff;
  font-size: 22px;
}
/* line 99, ../../app/styles/Imports/_navbar.scss */
.mobile-navbar .mobile-navbar-title .nav-product-title {
  padding-left: 10px;
}
/* line 104, ../../app/styles/Imports/_navbar.scss */
.mobile-navbar .arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 39px 0 39px 39px;
  border-color: transparent transparent transparent #28223D;
}

/* line 113, ../../app/styles/Imports/_navbar.scss */
.nav-user-name-limit {
  padding-top: 5px;
}

/* line 118, ../../app/styles/Imports/_navbar.scss */
.mobile-container-fluid {
  padding-left: 5px;
  padding-right: 0px;
}

/* line 123, ../../app/styles/Imports/_navbar.scss */
.nopadding-container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

/* line 129, ../../app/styles/Imports/_navbar.scss */
.mobile-navbar-brand {
  color: #777;
  float: none;
}

/* line 135, ../../app/styles/Imports/_navbar.scss */
.mobile-text-header {
  font-size: 18px;
  padding: 25px 5px;
}

/* line 140, ../../app/styles/Imports/_navbar.scss */
.mobile-center-container {
  min-height: 80px;
  padding-bottom: 19px;
  border-bottom: 1px solid #ccc;
}

/* line 146, ../../app/styles/Imports/_navbar.scss */
.mobile-left-container {
  background-color:#28223D;
  border-bottom: 1px solid #222;
  float: left;
  padding: 22px 12px;
  margin-top: 0;
  margin-left: -5px;
  width: 225px;
  height: 80px;
}

/* line 157, ../../app/styles/Imports/_navbar.scss */
.mobile-right-container {
  float: right;
  border-left: 1px solid #ccc;
  padding: 0;
  margin-left: 25px;
}
/* line 163, ../../app/styles/Imports/_navbar.scss */
.mobile-right-container .nav > li {
  border-bottom: 1px solid #ccc;
  height: 80px;
}
/* line 168, ../../app/styles/Imports/_navbar.scss */
.mobile-right-container .nav > li > a:hover, .mobile-right-container .nav > li > a:focus {
  border-bottom: 1px solid #ccc;
  height: 80px;
}

@media (max-width: 1199px) {
  /* line 179, ../../app/styles/Imports/_navbar.scss */
  .mobile-right-container .user-image {
    padding: 0;
  }
}
@media (max-width: 991px) {
  /* line 186, ../../app/styles/Imports/_navbar.scss */
  .mobile-navbar-title {
    margin-left: 10px;
  }
}
/* line 2, ../../app/styles/Imports/_leftPanel.scss */
#left-panel {
  top: 0;
  left: 0;
  z-index: 904;
  padding-top: 80px;
  width: 225px;
  position: fixed;
  background-color: #28223D;
  height: 100%;
}

/* line 1, ../../app/styles/Imports/_saPanel.scss */
.sa-panel {
  border-color: #bce8f1;
}
/* line 4, ../../app/styles/Imports/_saPanel.scss */
.sa-panel .panel-heading {
  color: #48829e;
  background-color: #d9edf7;
}
/* line 9, ../../app/styles/Imports/_saPanel.scss */
.sa-panel .panel-body {
  color: #333333;
  background-color: transparent;
}

/* line 1, ../../app/styles/Imports/_unauthorized.scss */
.list-icon {
  width: 30px;
  text-align: center;
}

/* line 6, ../../app/styles/Imports/_unauthorized.scss */
.list-description {
  padding-left: 5px;
}

/* line 10, ../../app/styles/Imports/_unauthorized.scss */
.product-list {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* line 15, ../../app/styles/Imports/_unauthorized.scss */
.btn-width {
  width: 240px;
}

/* line 19, ../../app/styles/Imports/_unauthorized.scss */
.btn {
  text-align: left;
}

/*
   This is the background of our overlay.
*/
/* line 4, ../../app/features/permissions/_managePermissions.scss */
.grid-overlay-msg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/*
  This guy will contain our message. Most
  importantly it's using "display: table" so
  we can vertically center its contents.
*/
/* line 17, ../../app/features/permissions/_managePermissions.scss */
.grid-overlay-msg .msg {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  text-align: center;
  display: table;
}

/*
  Lastly this is the actual message content. It uses
  display: table-cell so the vertical alignment
  works properly.
*/
/* line 34, ../../app/features/permissions/_managePermissions.scss */
.grid-overlay-msg .msg span {
  display: table-cell;
  vertical-align: middle;
}

/* line 2, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .block-title {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 5px;
}
/* line 8, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .btn {
  padding-left: 20px;
  padding-right: 20px;
}
/* line 13, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .grid-overlay-msg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
/* line 21, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .grid-overlay-msg .msg {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  text-align: center;
  display: table;
}
/* line 33, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .grid-overlay-msg .msg span {
  display: table-cell;
  vertical-align: middle;
}
/* line 38, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .input .col-sm-12 {
  padding-left: 0;
}
/* line 42, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .alert {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
}
/* line 48, ../../app/features/security/administrators/_saAdministrators.scss */
#administrators .external-administrators-text {
  margin-bottom: 15px;
}

/* line 2, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .divider {
  background-color: #e6e6e6;
  margin: 0 10px 10px 10px;
}
/* line 7, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .divider-margin-before {
  margin-top: 10px;
}
/* line 11, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-section-toggles {
  padding-top: 20px;
}
/* line 16, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .sa-phone .sa-phone-preview {
  height: 100%;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
/* line 27, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .sa-phone .sa-phone-preview .dcs-preview {
  width: 100%;
  height: 100%;
  background-color: #3c1365;
}
/* line 35, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-header-bar {
  width: 100%;
  height: 19px;
  background-color: black;
  white-space: nowrap;
}
/* line 41, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-header-bar .dcs-header-left,
#dataCollectionTab .dcs-header-bar .dcs-header-right {
  color: white;
  font-size: 8px;
  display: inline-block;
}
/* line 48, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-header-bar .dcs-header-left {
  width: 25%;
  padding-left: 5px;
}
/* line 52, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-header-bar .dcs-header-right {
  width: 17%;
  text-align: right;
}
/* line 57, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-header-bar .dcs-header-centre {
  color: white;
  font-size: 10px;
  display: inline-block;
  width: 50%;
}
/* line 65, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-section-list {
  height: calc(100% - 94px);
  width: 100%;
  overflow: auto;
}
/* line 71, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-bottom-bar {
  width: 100%;
  height: 35px;
  bottom: 0;
  position: absolute;
  margin-top: 5px;
  font-size: 10px;
}
/* line 79, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-bottom-bar .dcs-save, #dataCollectionTab .dcs-bottom-bar .dcs-revert {
  display: inline-block;
  text-align: center;
  color: white;
  height: 100%;
}
/* line 84, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-bottom-bar .dcs-save .dcs-bottom-bar-text, #dataCollectionTab .dcs-bottom-bar .dcs-revert .dcs-bottom-bar-text {
  line-height: 42px;
}
/* line 89, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-bottom-bar .dcs-save {
  width: 66%;
  background-color: #26bfad;
}
/* line 94, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-bottom-bar .dcs-revert {
  width: 34%;
  margin-left: -4px;
  background-color: #bcbabf;
}
/* line 102, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-contacts .dcs-contacts-separator {
  width: 100%;
  height: 17px;
  color: white;
  font-size: 10px;
  padding: 1px 0 1px 8px;
}
/* line 110, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-contacts .dcs-contacts-add {
  width: 100%;
  height: 26px;
  color: white;
  font-size: 10px;
  padding: 1px 0 1px 8px;
  background-color: #26bfad;
  line-height: 26px;
}
/* line 119, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .dcs-contacts .dcs-contacts-add i {
  margin-right: 8px;
}
/* line 126, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab sa-settings-array {
  padding-left: 0px;
  line-height: 28px;
}
/* line 131, ../../app/features/productSettings/Parent/dcs/_dcsParentSettings.scss */
#dataCollectionTab .section-heading {
  line-height: 28px;
  padding-left: 15px;
}

/* line 2, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .divider {
  background-color: #e6e6e6;
  margin: 0 10px 10px 10px;
}
/* line 7, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-toggles {
  padding-top: 20px;
}
/* line 12, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .sa-phone .sa-phone-preview {
  height: 100%;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
/* line 23, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .sa-phone .sa-phone-preview .conduct-preview {
  width: 100%;
  height: 100%;
  background-color: #3c1365;
}
/* line 31, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-header-bar {
  width: 100%;
  height: 19px;
  background-color: black;
  white-space: nowrap;
}
/* line 37, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-header-bar .conduct-header-left,
#conductTab .conduct-header-bar .conduct-header-right {
  color: white;
  font-size: 8px;
  display: inline-block;
}
/* line 44, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-header-bar .conduct-header-left {
  width: 37%;
  padding-left: 5px;
}
/* line 48, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-header-bar .conduct-header-right {
  width: 17%;
  text-align: right;
}
/* line 53, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-header-bar .conduct-header-centre {
  color: white;
  font-size: 10px;
  display: inline-block;
  width: 38%;
}
/* line 61, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list {
  height: calc(100% - 55px);
  width: 100%;
  font-family: "Helvetica Neue", "Segoe UI", "Arial,sans-serif", serif;
  overflow: auto;
}
/* line 67, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-row {
  padding: 0;
  width: 100%;
  display: flex;
}
/* line 73, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .day-header {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  background-color: transparent;
  height: 20px;
  width: 100%;
  position: relative;
}
/* line 83, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .day-header-title {
  color: white;
  font-size: 9px;
  text-align: left;
  position: absolute;
}
/* line 90, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .day-header-date {
  color: #9c4be3;
  font-size: 9px;
  text-align: right;
  padding-right: 7px;
  position: absolute;
  margin-top: 2px;
  right: 0;
}
/* line 100, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item {
  min-height: 10px;
  width: 100%;
}
/* line 105, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content {
  background-color: #fff;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid transparent;
  padding: 2px;
}
/* line 113, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.points-container {
  float: left;
  width: 14%;
  padding-top: 1px;
}
/* line 117, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.points-container span.points {
  height: 14px;
  width: 14px;
  font-size: 7px;
  color: #fff;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
}
/* line 126, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.points-container span.points.behaviour {
  background: #ca005d;
}
/* line 129, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.points-container span.points.achievement {
  background: #008566;
}
/* line 135, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container {
  float: right;
  width: 86%;
  line-height: 12px;
}
/* line 139, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div.award-type-container {
  margin-top: 0px;
  white-space: normal;
  word-wrap: break-word;
}
/* line 143, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div.award-type-container span.award-type {
  font-size: 10px;
  font-weight: bold;
}
/* line 146, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div.award-type-container span.award-type.behaviour {
  color: #ca005d;
}
/* line 149, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div.award-type-container span.award-type.achievement {
  color: #008566;
}
/* line 153, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div.award-type-container span.role-type {
  font-size: 7px;
}
/* line 158, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div span.activity-type {
  font-size: 9px;
  font-weight: bold;
}
/* line 163, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div {
  white-space: normal;
  word-wrap: break-word;
}
/* line 166, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div span.awarded-by-title {
  font-size: 9px;
}
/* line 169, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div span.awarded-by-content {
  font-size: 10px;
  padding-left: 2px;
}
/* line 175, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content div.content-container div span.comments {
  font-weight: normal;
  font-size: 8px;
  width: 100px;
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}
/* line 187, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .conduct-section-list .conduct-item-content td.times {
  font-size: 6px;
  text-align: center;
  width: 10px;
  border-right: 5px solid transparent;
}
/* line 196, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-container {
  min-width: 137px;
}
/* line 200, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-container {
  padding: 0;
  min-width: 0;
}
/* line 205, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-label-container {
  padding: 0;
}
/* line 208, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-label-container label {
  font-weight: normal;
  margin-top: 4px;
}
/* line 213, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-input-container {
  padding: 0;
  float: left;
  width: 67px;
  margin-left: 4px;
}
/* line 218, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-input-container input {
  padding: 5px 10px;
  height: 32px;
}
/* line 223, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-button-container {
  padding: 0;
  display: inline-block;
  margin-left: -5px;
}
/* line 227, ../../app/features/productSettings/Parent/conduct/_conductParentSettings.scss */
#conductTab .time-picker-button-container button {
  padding: 5px 10px;
}

/* line 1, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab {
  padding: 0;
}
/* line 4, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .remove-left-padding {
  padding-left: 0;
}
/* line 8, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .divider {
  background-color: #e6e6e6;
  margin: 0 10px 15px 10px;
}
/* line 13, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-heading {
  line-height: 33px;
}
/* line 17, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .button-container {
  margin-bottom: 15px;
  padding-right: 0;
}
/* line 20, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .button-container button {
  width: 80px;
}
/* line 22, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .button-container button i {
  margin-right: 5px;
}
/* line 28, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list {
  background-color: #f7f7f7;
  height: 350px;
  overflow-y: auto;
  margin-bottom: 15px;
  padding: 10px 10px 0 10px;
  overflow-x: hidden;
}
/* line 36, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .top-anchor {
  position: absolute;
  top: 0;
}
/* line 41, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .bottom-anchor {
  position: absolute;
  bottom: 0;
}
/* line 46, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-default-text {
  color: #999;
}
/* line 50, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item {
  width: 100%;
  height: 85px;
  border-radius: 4px;
  border: 1px transparent;
  background-color: #d6eaed;
  margin-bottom: 10px;
}
/* line 58, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-ordering-container {
  height: 85px;
  width: 25px;
  float: right;
  font-size: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/* line 66, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-ordering-container .marksheet-ordering-button-container {
  height: 25%;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  line-height: 21px;
}
/* line 73, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-ordering-container .marksheet-ordering-button-container .marksheet-ordering-button {
  width: 100%;
  height: 100%;
}
/* line 79, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-ordering-container :hover {
  background-color: #c9dde0;
  cursor: pointer;
}
/* line 83, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-ordering-container :hover .marksheet-ordering-button {
  background-color: transparent;
}
/* line 88, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-ordering-container .first-element, #assessmentTab .marksheet-list .marksheet-list-item .marksheet-ordering-container .last-element {
  pointer-events: none;
}
/* line 93, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-list-container {
  padding: 6px 0 6px 6px;
  width: calc(100% - 25px);
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/* line 101, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-list-container:hover {
  background-color: #c9dde0;
  cursor: pointer;
}
/* line 106, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-text-margin {
  margin-bottom: 2px;
}
/* line 110, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-item-header {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* line 117, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-item-text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* line 122, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-item-text-container .marksheet-item-text {
  font-size: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* line 128, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item .marksheet-item-text-container .marksheet-item-text span, #assessmentTab .marksheet-list .marksheet-list-item .marksheet-item-text-container .marksheet-item-text strong {
  font-size: 14px;
}
/* line 135, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentTab .marksheet-list .marksheet-list-item :hover .marksheet-list-container {
  background-color: #c9dde0;
  cursor: pointer;
}

/* line 143, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .dropdown-menu {
  right: initial !important;
}
/* line 147, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .top-row {
  margin-bottom: 15px;
  margin-top: 15px;
}
/* line 152, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .marksheet-select {
  border-radius: 4px;
  margin-left: 15px;
  height: 27px;
  border: 1px solid #c4c4c4;
}
/* line 159, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .warning-row {
  padding: 0 15px 0 15px;
}
/* line 162, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .warning-row .alert {
  height: 30px;
  line-height: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* line 171, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .row-with-margin {
  margin-bottom: 15px;
}
/* line 173, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .row-with-margin label {
  font-weight: normal;
}
/* line 178, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .marksheet-date-control {
  height: 27px;
  border: 1px solid #c4c4c4;
  margin-left: 15px;
  max-width: 200px;
  border-radius: 4px 0 0 4px;
}
/* line 186, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .marksheet-date-control-button {
  height: 27px;
  line-height: 0;
  border-radius: 0 4px 4px 0;
  border-left: none;
}
/* line 193, ../../app/features/productSettings/Parent/assessment/_assessmentParentSettings.scss */
#assessmentPopup .select-disabled {
  cursor: not-allowed;
  color: #bfbcbc;
}

/* line 1, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab {
  padding: 0;
}
/* line 3, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-container {
  min-width: 137px;
}
/* line 7, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-container {
  padding: 0;
  min-width: 0;
}
/* line 12, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-label-container {
  padding: 0;
}
/* line 15, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-label-container label {
  font-weight: normal;
  margin-top: 4px;
}
/* line 20, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-input-container {
  padding: 0;
  float: left;
  width: 69px;
  margin-left: 4px;
}
/* line 25, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-input-container input {
  padding: 5px 10px;
  height: 32px;
}
/* line 30, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-button-container {
  padding: 0;
  display: inline-block;
  margin-left: -5px;
}
/* line 34, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .time-picker-button-container button {
  padding: 5px 10px;
}
/* line 40, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .day-picker-container {
  padding: 0;
  width: 99px;
}
/* line 43, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .day-picker-container select {
  margin-left: 4px;
  padding-left: 1px;
  padding-right: 0px;
}
/* line 50, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .day-picker-label-container {
  padding: 0;
}
/* line 53, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .day-picker-label-container label {
  font-weight: normal;
}
/* line 58, ../../app/features/productSettings/Parent/attendance/_attendanceParentSettings.scss */
#attendanceTab .help-box-container {
  margin-top: 4px;
}

/* line 6, ../../app/features/productSettings/_productSettings.scss */
#settings {
  padding: 0;
  border: solid 1px #e6e6e6;
  color: #4D4D4D;
  display: inline-block;
}
/* line 29, ../../app/features/productSettings/_productSettings.scss */
#settings .paddle {
  height: 78px;
  line-height: 78px;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  display: table-cell;
  padding: 0 4px;
  font-size: 33px;
  color: #4D4D4D;
  background-color: #ccc;
  display: inline-block;
  position: relative;
}
@media (max-width: 992px) {
  /* line 46, ../../app/features/productSettings/_productSettings.scss */
  #settings .paddle {
    height: 64px;
    line-height: 64px;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    display: table-cell;
    padding: 0 4px;
    font-size: 33px;
    color: #4D4D4D;
    background-color: #ccc;
    display: inline-block;
    position: relative;
  }
}
/* line 62, ../../app/features/productSettings/_productSettings.scss */
#settings .backer-bar {
  background-color: #eee;
  height: 78px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
/* line 71, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header {
  padding: 0;
  margin: 0;
  display: flex;
  white-space: nowrap;
}
/* line 78, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header .settings-panel-header-inner {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}
/* line 84, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header .settings-panel-header-inner ul {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
/* line 91, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header .disabled {
  pointer-events: none;
  opacity: 0.6;
}
/* line 96, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li {
  display: inline-block;
  border-bottom: solid 1px #eee;
}
/* line 100, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .settings-panel-header-vertical-seperator {
  width: 2px;
  height: 26px;
  background-color: #E6E6E6;
  position: absolute;
  right: -1px;
  top: 26px;
}
/* line 109, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .settings-panel-header-vertical-seperator-container {
  position: absolute;
  height: 78px;
  width: 134px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 100;
}
/* line 118, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .settings-panel-header-vertical-seperator-container:hover ~ a {
  background-color: #fafafa;
  transition: all .1s ease-in;
}
/* line 121, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .settings-panel-header-vertical-seperator-container:hover ~ a .tab-icon, #settings .settings-panel-header li .settings-panel-header-vertical-seperator-container:hover ~ a .tab-heading {
  transition: all .1s ease-in;
}
/* line 128, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li a {
  height: 78px;
  width: 134px;
  display: block;
  padding: 10px;
  text-align: center;
  background-color: #eee;
}
/* line 136, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li a .tab-icon, #settings .settings-panel-header li a .tab-heading {
  position: relative;
  top: 0;
}
/* line 141, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li a .tab-heading {
  margin-top: 2px;
  font-size: 14px;
  color: #333333;
  display: block;
}
/* line 148, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li a .tab-icon {
  color: #333333;
  font-size: 21px;
}
/* line 155, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .arrow-down-border {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0 11px;
  border-color: #e6e6e6 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 55px;
  margin: 0 auto;
  clear: both;
}
/* line 169, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .arrow-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  bottom: 0;
  text-align: center;
  margin: 0 auto;
  margin-left: -10px;
  margin-bottom: 1px;
  bottom: 0;
}
/* line 184, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .tab-icon {
  display: block;
  font-size: 15px;
  margin-top: 7px;
}
/* line 191, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .coming-soon-tab-heading, #settings .settings-panel-header li .lock-out-tab-heading {
  color: #aaa;
  position: relative;
  display: block;
  top: 0;
  margin-top: 2px;
}
/* line 199, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .coming-soon-tab-icon, #settings .settings-panel-header li .lock-out-tab-icon {
  color: #aaa;
  font-size: 21px;
  display: block;
  position: relative;
  top: 0;
  margin-top: 7px;
}
/* line 208, ../../app/features/productSettings/_productSettings.scss */
#settings .settings-panel-header li .coming-soon-text {
  color: #4D4D4D;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 16px;
  transform-origin: top right;
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
@media (max-width: 1200px) {
  /* line 228, ../../app/features/productSettings/_productSettings.scss */
  #settings .backer-bar {
    height: 78px;
  }
  /* line 234, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .settings-panel-header-vertical-seperator {
    height: 26px;
    top: 28.5px;
  }
  /* line 239, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .settings-panel-header-vertical-seperator-container {
    height: 78px;
    width: 100px;
  }
  /* line 244, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li a {
    height: 78px;
    width: 100px;
    padding: 10px;
  }
  /* line 249, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li a .tab-heading {
    margin-top: 6px;
    font-size: 12px;
  }
  /* line 254, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li a .tab-icon {
    font-size: 18px;
  }
  /* line 259, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .arrow-down-border {
    left: 38px;
  }
  /* line 265, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-tab-heading, #settings .settings-panel-header li .lock-out-tab-heading {
    margin-top: 6px;
    font-size: 12px;
  }
  /* line 270, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-tab-icon, #settings .settings-panel-header li .lock-out-tab-icon {
    font-size: 18px;
  }
  /* line 274, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-text {
    font-size: 12px;
  }
  /* line 278, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-text {
    padding-top: 12px;
    margin-left: -12px;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  /* line 290, ../../app/features/productSettings/_productSettings.scss */
  #settings .backer-bar {
    height: 63px;
  }
  /* line 297, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .settings-panel-header-vertical-seperator {
    height: 21px;
    top: 21px;
  }
  /* line 302, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .settings-panel-header-vertical-seperator-container {
    height: 63px;
    width: 92px;
  }
  /* line 307, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li a {
    height: 63px;
    width: 92px;
    padding: 6px;
  }
  /* line 312, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li a .tab-heading {
    margin-top: 0;
    font-size: 10px;
  }
  /* line 317, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li a .tab-icon {
    line-height: 18px;
    font-size: 18px;
  }
  /* line 323, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .arrow-down-border {
    left: 35px;
  }
  /* line 327, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-tab-heading, #settings .settings-panel-header li .lock-out-tab-heading {
    margin-top: 0;
    font-size: 10px;
  }
  /* line 332, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-tab-icon, #settings .settings-panel-header li .lock-out-tab-icon {
    font-size: 18px;
    line-height: 18px;
  }
  /* line 337, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-text {
    font-size: 10px;
  }
  /* line 341, ../../app/features/productSettings/_productSettings.scss */
  #settings .settings-panel-header li .coming-soon-text {
    padding-top: 12px;
    margin-left: 0;
    font-size: 10px;
  }
}
/* line 351, ../../app/features/productSettings/_productSettings.scss */
#settings li.active .settings-panel-header-vertical-seperator-container:hover ~ a {
  background: #ffffff;
}
/* line 354, ../../app/features/productSettings/_productSettings.scss */
#settings li.active .settings-panel-header-vertical-seperator-container:hover ~ a .tab-icon, #settings li.active .settings-panel-header-vertical-seperator-container:hover ~ a .tab-heading {
  top: 0;
}
/* line 359, ../../app/features/productSettings/_productSettings.scss */
#settings li.active a {
  background: #ffffff;
  border-color: #333333;
}
/* line 366, ../../app/features/productSettings/_productSettings.scss */
#settings #settings-content {
  margin: 0;
  border-top: 0;
  min-height: 200px;
  padding: 30px;
}
/* line 373, ../../app/features/productSettings/_productSettings.scss */
#settings hr {
  margin: 10px 10px 0 10px;
}
/* line 377, ../../app/features/productSettings/_productSettings.scss */
#settings .save-settings {
  z-index: 60;
  margin: 10px;
}
/* line 382, ../../app/features/productSettings/_productSettings.scss */
#settings .warning-text {
  color: #FF0000;
}

/*
   This is the background of our overlay.
*/
/* line 4, ../../app/features/common/directives/loadingIndicator/_loadingIndicator.scss */
.loading-indicator-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/*
  This will contain our content. Most
  importantly it's using "display: table" so
  we can vertically center its contents.
*/
/* line 17, ../../app/features/common/directives/loadingIndicator/_loadingIndicator.scss */
.loading-indicator-overlay .loading-indicator {
  opacity: 1;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
}

/* line 29, ../../app/features/common/directives/loadingIndicator/_loadingIndicator.scss */
.loading-indicator-overlay .loading-indicator.solidBackground {
  background-color: #eee;
}

/*
  Lastly this is the actual indicator content. It uses
  display: table-cell so the vertical alignment
  works properly.
*/
/* line 38, ../../app/features/common/directives/loadingIndicator/_loadingIndicator.scss */
.loading-indicator-overlay .loading-indicator span {
  display: table-cell;
  vertical-align: middle;
}

/* line 43, ../../app/features/common/directives/loadingIndicator/_loadingIndicator.scss */
.loading-indicator-non-overlay {
  text-align: center;
}

/* line 47, ../../app/features/common/directives/loadingIndicator/_loadingIndicator.scss */
.loading-indicator-non-overlay .loading-indicator.solidBackground {
  background-color: #eee;
}

/* line 1, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list {
  width: 100%;
  padding: 40px 28px 25px 0;
  padding: 0;
  margin: 0;
  font-size: 17px;
  line-height: .5em;
  list-style: none;
  position: relative;
}
/* line 11, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list a:focus {
  outline: none !important;
}
/* line 15, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li {
  cursor: pointer;
}
/* line 18, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner {
  position: relative;
  height: 100%;
}
/* line 22, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner .tier2 .li-inner {
  padding-left: 15px;
}
/* line 26, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner .left-nav-li-hover-bar {
  position: absolute;
  height: 100%;
  width: 7px;
  background-color: #fff;
  display: none;
  z-index: 100;
}
/* line 35, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner .left-nav-li-hover-bead {
  background-color: #fff;
  position: absolute;
  height: 12px;
  width: 12px;
  top: 15px;
  left: 0;
  transform: rotate(45deg);
  display: none;
}



/* line 46, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner .left-nav-li-current {
  position: absolute;
  top: 12px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 12px 0px;
  border-color: transparent #fff transparent transparent;
}
/* line 58, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner a {
  color: #cfd1d2;
  line-height: normal;
  padding: 12px 10px;
  display: block;
  font-weight: 400;
  text-decoration: none !important;
  position: relative;
}
/* line 67, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner a i {
  width: 30px;
  text-align: center;
}
/* line 72, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner a i.down, .left-nav-list li .li-inner a i.up {
  display: inline-block;
  position: absolute;
  right: 12px;
}
/* line 78, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner a i.down {
  display: inline-block;
}
/* line 82, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li .li-inner a i.up {
  display: none;
}
/* line 89, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li.active {
  background-color: #574B85;
}
/* line 90, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li.active a {
  color: #fff;
}
/* line 97, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list .tier2 li.active {
  background-color: #463C6B;
}
/* line 103, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li:hover a {
  color: #fff !important;
}
/* line 109, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li:hover > .li-inner > .left-nav-li-hover-bead {
  display: block !important;
}

.tier2 li:hover .left-nav-li-hover-bead{
  display: block !important;
}

/* line 114, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li:hover > .li-inner > .left-nav-li-hover-bar {
  display: block !important;
}



/* line 122, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li.expanded a i.down {
  display: none;
}
/* line 126, ../../app/features/common/directives/leftNav/_leftNav.scss */
.left-nav-list li.expanded a i.up {
  display: inline-block;
}

@media (max-width: 991px) {
  /* line 134, ../../app/features/common/directives/leftNav/_leftNav.scss */
  .mobile-left-container {
    padding: 22px 2px;
    width: 60px;
  }

  /* line 139, ../../app/features/common/directives/leftNav/_leftNav.scss */
  #left-panel {
    padding: 80px 0 10px 0;
    width: 60px;
  }

  /* line 144, ../../app/features/common/directives/leftNav/_leftNav.scss */
  .tier2 li .li-inner {
    padding-left: 3px!important;
  }
}
/* line 1, ../../app/features/common/directives/formAlerts/_formAlerts.scss */
sa-success-alert, sa-error-alert {
  padding: 0;
  margin: 0;
  min-height: 0;
  display: block;
  margin-left: -15px;
  padding-right: 0 !important;
}
/* line 11, ../../app/features/common/directives/formAlerts/_formAlerts.scss */
sa-success-alert .alert, sa-error-alert .alert {
  margin-bottom: 10px !important;
}
/* line 15, ../../app/features/common/directives/formAlerts/_formAlerts.scss */
sa-success-alert .alert-human-time, sa-error-alert .alert-human-time {
  font-size: 10px;
}
/* line 19, ../../app/features/common/directives/formAlerts/_formAlerts.scss */
sa-success-alert .greyed-out, sa-error-alert .greyed-out {
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 2000ms;
}

/* line 2, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
ui-grid-filter .ui-grid-filter-container {
  width: 100%;
}
/* line 6, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
ui-grid-filter .filter-button {
  width: 100%;
  padding: 0;
  border-radius: 0;
  font-weight: 700;
}
/* line 12, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
ui-grid-filter .filter-button .filter-text {
  float: left;
  width: calc(100% - 12px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

/* line 22, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu {
  min-width: 0;
  right: auto !important;
}
/* line 26, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu button {
  float: right;
}


/* line 30, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu input[type=checkbox] {
  -ms-transform: scale(1.3) !important;
  -moz-transform: scale(1.3) !important;
  -webkit-transform: scale(1.3) !important;
  -o-transform: scale(1.3) !important;
}
/* line 37, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu .filter-menu {
  padding: 5px;
}
/* line 41, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu .filter-options {
  padding: 0 5px;
  max-height: 215px;
  overflow-y: auto;
}
/* line 47, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu .filter-label {
  padding-left: 3px;
  padding-right: 5px;
  font-weight: normal;
}
/* line 53, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu .filter-confirm-btn {
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
/* line 59, ../../app/features/common/directives/uiGridFilter/uiGridFilter.scss */
.dropdown-menu .btn-group-vertical {
  display: block;
}

/* line 2, ../../app/features/common/directives/missingSimsInformationAlert/saMissingSimsInformationAlert.scss */
#saMissingSimsInformationAlert .alert {
  margin-bottom: 0;
  font-size: 12px;
  padding: 11px 18px;
  line-height: 18px;
}
/* line 8, ../../app/features/common/directives/missingSimsInformationAlert/saMissingSimsInformationAlert.scss */
#saMissingSimsInformationAlert .alert .action-required-header {
  margin-bottom: 10px;
  font-size: 14px;
  display: inline-block;
}
/* line 14, ../../app/features/common/directives/missingSimsInformationAlert/saMissingSimsInformationAlert.scss */
#saMissingSimsInformationAlert .alert .information-section {
  margin-bottom: 13px;
}
/* line 17, ../../app/features/common/directives/missingSimsInformationAlert/saMissingSimsInformationAlert.scss */
#saMissingSimsInformationAlert .alert .information-section .information-section-title {
  line-height: 20px;
}

/* line 1, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray {
  padding: 0;
}
/* line 4, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .no-padding {
  padding: 0;
}
/* line 8, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .disabled {
  pointer-events: none;
  opacity: 0.6;
}
/* line 13, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
/* line 20, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .array-container {
  border: #e6e6e6 1px;
  padding: 10px;
  background-color: #f7f7f7;
  padding-bottom: 0;
  outline: none;
}
/* line 28, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .edit-button {
  background-color: transparent;
  color: #66797c;
  border: none;
  font-size: 18px;
  outline: none;
}
/* line 36, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .edit-button:hover {
  color: black;
}
/* line 40, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .categories {
  border: transparent 1px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: #d6eaed;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
}
/* line 49, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .categories .close-button {
  font-size: 14px;
  border: none;
  background-color: transparent;
  color: #85989b;
  padding-left: 5px;
}
/* line 57, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .categories .category-text {
  padding: 5px 15px 5px 10px;
  white-space: nowrap;
}
/* line 63, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .categories:hover {
  background-color: #c9dde0;
}
/* line 65, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .categories:hover .close-button {
  color: #3a4c4f;
}
/* line 70, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .empty-categories {
  border: transparent 1px;
  border-radius: 10px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
  padding: 5px 15px 5px 10px;
  white-space: nowrap;
  color: #999;
}
/* line 81, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .settings-expander {
  position: absolute;
  width: 100%;
  z-index: 50;
  outline: none;
}
/* line 88, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .expanded-container {
  border: solid #F1F1F1 2px;
  background-color: #f7f7f7;
  padding: 0;
  height: 300px;
  overflow-y: auto;
  float: none;
}
/* line 97, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .expanded-item {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
}
/* line 103, ../../app/features/productSettings/directives/array/_saSettingsArray.scss */
#saSettingsArray .expanded-item:hover {
  background-color: #d6eaed;
}

/* line 1, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-menu-item {
  width: 100%;
  height: 26px;
  background-color: white;
  border-bottom: 1px solid #cccccc;
  color: #4c4c4c;
}
/* line 8, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-menu-item .dcs-menu-item-icon-container {
  float: left;
  width: 19px;
  font-size: 18px;
  height: 100%;
  padding-left: 6px;
}
/* line 15, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-menu-item .dcs-menu-item-icon-container i {
  line-height: 26px;
}
/* line 20, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-menu-item .dcs-menu-item-text-container {
  overflow: hidden;
  white-space: normal;
  height: 100%;
  padding: 3px 0 1px 10px;
  width: 125px;
  display: inline-block;
}
/* line 28, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-menu-item .dcs-menu-item-text-container .dcs-menu-item-title {
  font-size: 11px;
  line-height: 10px;
}
/* line 33, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-menu-item .dcs-menu-item-text-container .dcs-menu-item-changes {
  font-size: 8px;
  color: #bcbabf;
}
/* line 39, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-menu-item .dcs-menu-item-chevron {
  padding-left: 5px;
  font-size: 15px;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  line-height: 26px;
}

/* line 49, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-child-selector {
  width: 100%;
  height: 35px;
  color: white;
  background-color: #24113E;
}
/* line 54, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-child-selector .dcs-child-bar {
  line-height: 35px;
  padding-left: 4px;
}
/* line 58, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-child-selector .dcs-child-bar .dcs-child-indicator {
  width: 21px;
  height: 21px;
  text-align: center;
  line-height: 25px;
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
}
/* line 68, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-child-selector .dcs-child-bar .dcs-child-name {
  display: inline-block;
  font-size: 7px;
  margin-left: 5px;
}
/* line 74, ../../app/features/productSettings/directives/phone/dataCollection/_saPhoneDataCollection.scss */
.dcs-child-selector .dcs-child-bar .dcs-child-dropdown {
  float: right;
  font-size: 7px;
  margin-right: 6px;
}

/* line 1, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-menu-item {
  width: 100%;
  height: 26px;
  background-color: white;
  border-bottom: 1px solid #cccccc;
  color: #4c4c4c;
}
/* line 8, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-menu-item .common-menu-item-icon-container {
  float: left;
  width: 19px;
  font-size: 18px;
  height: 100%;
  padding-left: 6px;
}
/* line 15, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-menu-item .common-menu-item-icon-container i {
  line-height: 26px;
}
/* line 20, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-menu-item .common-menu-item-text-container {
  overflow: hidden;
  white-space: normal;
  height: 100%;
  padding: 3px 0 1px 10px;
  width: 125px;
  display: inline-block;
}
/* line 28, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-menu-item .common-menu-item-text-container .common-menu-item-title {
  font-size: 11px;
  line-height: 10px;
}
/* line 33, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-menu-item .common-menu-item-text-container .common-menu-item-changes {
  font-size: 8px;
  color: #bcbabf;
}
/* line 39, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-menu-item .common-menu-item-chevron {
  padding-left: 5px;
  font-size: 15px;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  line-height: 26px;
}

/* line 49, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-child-selector {
  width: 100%;
  height: 35px;
  color: white;
  background-color: #24113E;
}
/* line 55, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-child-selector .common-child-bar {
  line-height: 35px;
  padding-left: 7px;
}
/* line 59, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-child-selector .common-child-bar .common-child-indicator {
  width: 21px;
  height: 21px;
  text-align: center;
  line-height: 25px;
  font-size: 16px;
  display: inline-block;
  margin-left: 3px;
}
/* line 69, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-child-selector .common-child-bar .common-child-name {
  display: inline-block;
  font-size: 7px;
  margin-left: 5px;
}
/* line 75, ../../app/features/productSettings/directives/phone/common/_saPhoneCommon.scss */
.common-child-selector .common-child-bar .common-child-dropdown {
  float: right;
  font-size: 7px;
  margin-right: 6px;
}

/* line 4, ../../app/features/productSettings/directives/phone/_saPhone.scss */
.sa-phone {
  background-image: url(/images/cached/phoneOff.jpg);
  background-repeat: no-repeat;
  width: 193px;
  height: 488px;
  background-size: contain;
}
/* line 11, ../../app/features/productSettings/directives/phone/_saPhone.scss */
.sa-phone .sa-phone-content {
  position: relative;
  left: 14px;
  top: 42px;
  height: 290px;
  width: 166px;
  overflow: hidden;
}
/* line 19, ../../app/features/productSettings/directives/phone/_saPhone.scss */
.sa-phone .sa-phone-content img {
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* line 2, ../../app/features/common/directives/helpBox/_saHelpBox.scss */
#helpBox .help-body {
  color: #535969;
}
/* line 5, ../../app/features/common/directives/helpBox/_saHelpBox.scss */
#helpBox .help-body strong {
  display: inline-block;
  margin-bottom: 10px;
}
/* line 11, ../../app/features/common/directives/helpBox/_saHelpBox.scss */
#helpBox .help-title {
  background-color: #d9edf7;
  color: #327190;
}

@media (max-width: 500px) {
  /* line 17, ../../app/features/common/directives/helpBox/_saHelpBox.scss */
  #helpBox {
    display: none;
  }
}
/* line 4, ../../app/features/productSettings/directives/toggle/_saToggle.scss */
#saToggle .toggle-label label {
  padding: 0;
  font-weight: normal;
  text-align: left;
}
/* line 11, ../../app/features/productSettings/directives/toggle/_saToggle.scss */
#saToggle .toggle {
  display: inline-block;
  margin-top: -3px;
}

/* line 1, ../../app/features/customisations/_customisations.scss */
#customisations {
  padding: 0;
  border: solid 1px #e6e6e6;
  color: #4D4D4D;
  display: inline-block;
}
/* line 24, ../../app/features/customisations/_customisations.scss */
#customisations .paddle {
  height: 78px;
  line-height: 78px;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  display: table-cell;
  padding: 0 4px;
  font-size: 33px;
  color: #4D4D4D;
  background-color: #ccc;
  display: inline-block;
  position: relative;
}
@media (max-width: 992px) {
  /* line 41, ../../app/features/customisations/_customisations.scss */
  #customisations .paddle {
    height: 64px;
    line-height: 64px;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    display: table-cell;
    padding: 0 4px;
    font-size: 33px;
    color: #4D4D4D;
    background-color: #ccc;
    display: inline-block;
    position: relative;
  }
}
/* line 57, ../../app/features/customisations/_customisations.scss */
#customisations .backer-bar {
  background-color: #eee;
  height: 78px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
/* line 66, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header {
  padding: 0;
  margin: 0;
  display: flex;
  white-space: nowrap;
}
/* line 73, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header .settings-panel-header-inner {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}
/* line 79, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header .settings-panel-header-inner ul {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
/* line 86, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header .disabled {
  pointer-events: none;
  opacity: 0.6;
}
/* line 91, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li {
  display: inline-block;
  border-bottom: solid 1px #eee;
}
/* line 95, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .settings-panel-header-vertical-seperator {
  width: 2px;
  height: 26px;
  background-color: #E6E6E6;
  position: absolute;
  right: -1px;
  top: 26px;
}
/* line 104, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .settings-panel-header-vertical-seperator-container {
  position: absolute;
  height: 78px;
  width: 134px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 100;
}
/* line 113, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .settings-panel-header-vertical-seperator-container:hover ~ a {
  background-color: #fafafa;
  transition: all .1s ease-in;
}
/* line 116, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .settings-panel-header-vertical-seperator-container:hover ~ a .tab-icon, #customisations .settings-panel-header li .settings-panel-header-vertical-seperator-container:hover ~ a .tab-heading {
  transition: all .1s ease-in;
}
/* line 123, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li a {
  height: 78px;
  width: 134px;
  display: block;
  padding: 10px;
  text-align: center;
  background-color: #eee;
}
/* line 131, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li a .tab-icon, #customisations .settings-panel-header li a .tab-heading {
  position: relative;
  top: 0;
}
/* line 136, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li a .tab-heading {
  margin-top: 2px;
  font-size: 14px;
  color: #333333;
  display: block;
}
/* line 143, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li a .tab-icon {
  color: #333333;
  font-size: 21px;
}
/* line 150, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .arrow-down-border {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0 11px;
  border-color: #e6e6e6 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 55px;
  margin: 0 auto;
  clear: both;
}
/* line 164, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .arrow-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  bottom: 0;
  text-align: center;
  margin: 0 auto;
  margin-left: -10px;
  margin-bottom: 1px;
  bottom: 0;
}
/* line 179, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .tab-icon {
  display: block;
  font-size: 15px;
  margin-top: 7px;
}
/* line 186, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .coming-soon-tab-heading {
  color: #aaa;
  position: relative;
  display: block;
  top: 0;
  margin-top: 2px;
}
/* line 194, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .coming-soon-tab-icon {
  color: #aaa;
  font-size: 21px;
  display: block;
  position: relative;
  top: 0;
  margin-top: 7px;
}
/* line 203, ../../app/features/customisations/_customisations.scss */
#customisations .settings-panel-header li .coming-soon-text {
  color: #4D4D4D;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 16px;
  transform-origin: top right;
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
@media (max-width: 1200px) {
  /* line 223, ../../app/features/customisations/_customisations.scss */
  #customisations .backer-bar {
    height: 78px;
  }
  /* line 229, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .settings-panel-header-vertical-seperator {
    height: 26px;
    top: 28.5px;
  }
  /* line 234, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .settings-panel-header-vertical-seperator-container {
    height: 78px;
    width: 100px;
  }
  /* line 239, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li a {
    height: 78px;
    width: 100px;
    padding: 10px;
  }
  /* line 244, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li a .tab-heading {
    margin-top: 6px;
    font-size: 12px;
  }
  /* line 249, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li a .tab-icon {
    font-size: 18px;
  }
  /* line 254, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .arrow-down-border {
    left: 38px;
  }
  /* line 260, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-tab-heading {
    margin-top: 6px;
    font-size: 12px;
  }
  /* line 265, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-tab-icon {
    font-size: 18px;
  }
  /* line 269, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-text {
    font-size: 12px;
  }
  /* line 273, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-text {
    padding-top: 12px;
    margin-left: -12px;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  /* line 285, ../../app/features/customisations/_customisations.scss */
  #customisations .backer-bar {
    height: 63px;
  }
  /* line 292, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .settings-panel-header-vertical-seperator {
    height: 21px;
    top: 21px;
  }
  /* line 297, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .settings-panel-header-vertical-seperator-container {
    height: 63px;
    width: 92px;
  }
  /* line 302, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li a {
    height: 63px;
    width: 92px;
    padding: 6px;
  }
  /* line 307, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li a .tab-heading {
    margin-top: 0;
    font-size: 10px;
  }
  /* line 312, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li a .tab-icon {
    line-height: 18px;
    font-size: 18px;
  }
  /* line 318, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .arrow-down-border {
    left: 35px;
  }
  /* line 322, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-tab-heading {
    margin-top: 0;
    font-size: 10px;
  }
  /* line 327, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-tab-icon {
    font-size: 18px;
    line-height: 18px;
  }
  /* line 332, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-text {
    font-size: 10px;
  }
  /* line 336, ../../app/features/customisations/_customisations.scss */
  #customisations .settings-panel-header li .coming-soon-text {
    padding-top: 12px;
    margin-left: 0;
    font-size: 10px;
  }
}
/* line 346, ../../app/features/customisations/_customisations.scss */
#customisations li.active .settings-panel-header-vertical-seperator-container:hover ~ a {
  background: #ffffff;
}
/* line 349, ../../app/features/customisations/_customisations.scss */
#customisations li.active .settings-panel-header-vertical-seperator-container:hover ~ a .tab-icon, #customisations li.active .settings-panel-header-vertical-seperator-container:hover ~ a .tab-heading {
  top: 0;
}
/* line 354, ../../app/features/customisations/_customisations.scss */
#customisations li.active a {
  background: #ffffff;
  border-color: #333333;
}
/* line 361, ../../app/features/customisations/_customisations.scss */
#customisations #settings-content {
  margin: 0;
  border-top: 0;
  min-height: 200px;
  padding: 30px;
}
/* line 368, ../../app/features/customisations/_customisations.scss */
#customisations hr {
  margin: 10px 10px 0 10px;
}
/* line 372, ../../app/features/customisations/_customisations.scss */
#customisations .save-settings {
  z-index: 60;
  margin: 10px;
}
/* line 378, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .theme-select {
  height: 40px;
  width: 100px;
  margin-right: 5px;
  border: 5px solid white;
}
/* line 385, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .theme-select.selected {
  border: 5px solid #d9edf7;
}
/* line 389, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .no-left-padding {
  padding-left: 0px;
}
/* line 393, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .theme-select-purple {
  background-color: #71439a;
}
/* line 397, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .theme-select-blue {
  background-color: #00a2e8;
}
/* line 401, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .theme-select-grey {
  background-color: #8b8d8e;
}
/* line 405, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .theme-select-red {
  background-color: #ff5e5e;
}
/* line 409, ../../app/features/customisations/_customisations.scss */
#customisations .theme-selection .theme-select-green {
  background-color: #33a68d;
}
/* line 414, ../../app/features/customisations/_customisations.scss */
#customisations .no-left-padding {
  padding-left: 0;
}
/* line 418, ../../app/features/customisations/_customisations.scss */
#customisations .banner-label {
  padding-left: 0;
  padding-right: 0;
}
/* line 423, ../../app/features/customisations/_customisations.scss */
#customisations .action-button {
  margin-bottom: 5px;
  width: 80px;
}
/* line 428, ../../app/features/customisations/_customisations.scss */
#customisations .image-preview-container {
  padding-bottom: 5px;
  position: relative;
}
/* line 431, ../../app/features/customisations/_customisations.scss */
#customisations .image-preview-container .banner-image {
  padding: 0;
  background-size: cover;
  background-position: center center;
  height: 146px;
  border: 1px solid #ccc;
}
/* line 438, ../../app/features/customisations/_customisations.scss */
#customisations .image-preview-container .banner-image.image-error {
  border: 1px solid pink;
  padding: 5px;
}
/* line 444, ../../app/features/customisations/_customisations.scss */
#customisations .image-preview-container .banner-image.image-error::before {
  content: "Error loading image";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* line 453, ../../app/features/customisations/_customisations.scss */
#customisations .image-preview-container .no-image-selected {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  border: 0;
}
/* line 465, ../../app/features/customisations/_customisations.scss */
#customisations .banner-help {
  margin-top: 14px;
}

@font-face {
  font-family: "sos-font";
  src: url("/fonts/sos-font.eot") format("embedded-opentype"), url("/fonts/sos-font.woff") format("woff"), url("/fonts/sos-font.ttf") format("truetype"), url("/fonts/sos-font.svg") format("svg");
}
/* line 9, ../../app/styles/Imports/_sosFont.scss */
.sos-font {
  line-height: 1;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 17, ../../app/styles/Imports/_sosFont.scss */
.sos-font:before {
  font-family: sos-font !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}

/* line 24, ../../app/styles/Imports/_sosFont.scss */
.sos-dcs-pending:before {
  content: "\f101";
}

/* line 27, ../../app/styles/Imports/_sosFont.scss */
.sos-queue-eater:before {
  content: "\f102";
}

/* line 30, ../../app/styles/Imports/_sosFont.scss */
.sos-passport:before {
  content: "\f103";
}

/* line 33, ../../app/styles/Imports/_sosFont.scss */
.sos-parent:before {
  content: "\f104";
}

/* line 36, ../../app/styles/Imports/_sosFont.scss */
.sos-student:before {
  content: "\f105";
}

/* line 39, ../../app/styles/Imports/_sosFont.scss */
.sos-activities:before {
  content: "\f106";
}

/* line 6, ../../app/features/invites/_manageUsers.scss */
#manageUsers .button-background {
  background-color: #fafafa;
}
/* line 10, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-1 li {
  width: auto;
}
/* line 13, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-2 li {
  width: auto;
}
/* line 16, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-3 li {
  width: auto;
}
/* line 19, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-4 li {
  width: auto;
}
/* line 22, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-5 li {
  width: auto;
}
/* line 25, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-6 li {
  width: auto;
}
/* line 28, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-7 li {
  width: auto;
}
/* line 31, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-8 li {
  width: auto;
}
/* line 34, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-9 li {
  width: auto;
}
/* line 37, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator.steps-10 li {
  width: auto;
}
/* line 41, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator {
  padding: 0;
  display: table;
}
/* line 46, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator:before {
  height: 0;
}
/* line 50, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li {
  text-align: left;
}
/* line 54, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li a {
  color: #4D4D4D;
  background-color: #eee;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  padding: 5px 30px 5px;
  position: relative;
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 30px;
  cursor: pointer;
  transition: 0.25s;
}
/* line 72, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li a:hover {
  color: #4D4D4D;
  text-shadow: 1px 0px 0px black;
  background-color: #fafafa;
}
/* line 78, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 21px solid white;
  background-color: transparent;
  content: '';
  transition: none;
}
/* line 93, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li a:after {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 21px solid #eee;
  position: absolute;
  content: '';
  top: 0;
  right: -21px;
  z-index: 2;
  transition: 0.25s;
}
/* line 107, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li a:hover:after {
  color: #4D4D4D;
  border-left-color: #fafafa;
}
/* line 112, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li:first-child a:before {
  border: none;
}
/* line 116, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li.done a:before {
  background-color: transparent;
}
/* line 120, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li.editing a {
  text-shadow: 1px 0px 0px black;
  background-color: #ddd;
}
/* line 125, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li.editing a:before {
  background-color: #ddd;
}
/* line 129, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li.editing a:after {
  border-left: 21px solid #ddd;
}
/* line 133, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li.current a {
  text-shadow: 1px 0px 0px black;
  background-color: #ddd;
}
/* line 138, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li.current a:before {
  background-color: #ddd;
}
/* line 142, ../../app/features/invites/_manageUsers.scss */
#manageUsers .steps-indicator li.current a:after {
  border-left: 21px solid #ddd;
}
/* line 146, ../../app/features/invites/_manageUsers.scss */
#manageUsers .spread-buttons {
  padding: 0px 5px 0px 5px;
}
/* line 151, ../../app/features/invites/_manageUsers.scss */
#manageUsers .list-group button {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
/* line 156, ../../app/features/invites/_manageUsers.scss */
#manageUsers .list-group button .title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.1;
}
/* line 162, ../../app/features/invites/_manageUsers.scss */
#manageUsers .list-group button .body {
  font-size: 12px;
  font-weight: normal;
  padding-top: 10px;
  margin-bottom: -3px;
}
/* line 170, ../../app/features/invites/_manageUsers.scss */
#manageUsers .step-heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
/* line 176, ../../app/features/invites/_manageUsers.scss */
#manageUsers .step-heading,
#manageUsers .help {
  padding-left: 15px;
  padding-right: 15px;
}
/* line 182, ../../app/features/invites/_manageUsers.scss */
#manageUsers .block-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 1.1;
}
/* line 191, ../../app/features/invites/_manageUsers.scss */
#manageUsers .button-row {
  position: relative;
  margin-top: 15px;
  height: 33px;
}
/* line 196, ../../app/features/invites/_manageUsers.scss */
#manageUsers .button-row button.right {
  position: absolute;
  top: 0px;
  right: 0px;
}
/* line 203, ../../app/features/invites/_manageUsers.scss */
#manageUsers .grid .no-data {
  padding: 5px;
  height: 30px;
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 60px;
}
/* line 212, ../../app/features/invites/_manageUsers.scss */
#manageUsers .inviteCode {
  text-align: center;
}
/* line 215, ../../app/features/invites/_manageUsers.scss */
#manageUsers .inviteCode button, #manageUsers .inviteCode div {
  margin-top: 5px;
}
/* line 220, ../../app/features/invites/_manageUsers.scss */
#manageUsers .selection-count {
  top: 1px;
  position: relative;
  left: 8px;
}

/* line 2, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-container {
  min-width: 137px;
}
/* line 6, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .disabled {
  opacity: 0.65;
  box-shadow: none;
}
/* line 11, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-container {
  padding: 0;
  min-width: 0;
  margin-bottom: 15px;
}
/* line 17, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-label-container {
  padding: 0;
}
/* line 20, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-label-container label {
  font-weight: normal;
  margin-top: 4px;
}
/* line 26, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-input-container input:focus {
  outline: none;
}
/* line 30, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-button-container {
  padding: 0;
  display: inline-block;
  margin-left: -5px;
  min-width: 110px;
}
/* line 35, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-button-container button {
  padding: 5px 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ffffff;
  border-color: #ccc;
}
/* line 43, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-button-container ul {
  right: initial !important;
  left: -17px !important;
}
/* line 48, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-button-container button:hover,
#saTimePicker .time-picker-button-container button:active {
  background-color: #ffffff;
  border-color: #ccc;
}
/* line 54, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-button-container .time-picker-input-container {
  padding: 0;
  float: left;
  width: 67px;
  margin-left: 4px;
}
/* line 60, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-button-container .time-picker-input-container input {
  padding: 5px 10px;
  height: 32px;
  pointer-events: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
/* line 70, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-button-container .time-picker-input-container:hover {
  text-decoration: none;
}
/* line 75, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .open .btn-default.dropdown-toggle, #saTimePicker .open .dropdown-toggle.btn-mobile-header {
  background-color: #ffffff;
  border-color: #ccc;
}
/* line 81, ../../app/features/productSettings/directives/timePicker/_saTimePicker.scss */
#saTimePicker .time-picker-selector .uib-time {
  border-color: transparent;
  box-shadow: none;
  pointer-events: none;
}

/* line 1, ../../app/features/messageLog/messageLog.scss */
#messageLog {
  padding-left: 15px;
}
/* line 4, ../../app/features/messageLog/messageLog.scss */
#messageLog #criteria {
  padding-top: 10px;
}
/* line 8, ../../app/features/messageLog/messageLog.scss */
#messageLog .row-with-margin {
  margin-bottom: 15px;
}
/* line 10, ../../app/features/messageLog/messageLog.scss */
#messageLog .row-with-margin label {
  font-weight: normal;
}
/* line 15, ../../app/features/messageLog/messageLog.scss */
#messageLog .block-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 1.1;
}
/* line 24, ../../app/features/messageLog/messageLog.scss */
#messageLog .date-control {
  height: 27px;
  border: 1px solid #c4c4c4;
  margin-left: 15px;
  max-width: 200px;
  border-radius: 4px 0 0 4px;
}
/* line 32, ../../app/features/messageLog/messageLog.scss */
#messageLog .date-control-button {
  height: 27px;
  line-height: 0;
  border-radius: 0 4px 4px 0;
  border-left: none;
}
/* line 39, ../../app/features/messageLog/messageLog.scss */
#messageLog .recipient-select {
  border-radius: 4px;
  height: 27px;
  border: 1px solid #c4c4c4;
  padding: 0px 0px 0px 10px;
  width: 100%;
}
@media (max-width: 991px) {
  /* line 48, ../../app/features/messageLog/messageLog.scss */
  #messageLog .help-box-container {
    padding-left: 0px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  /* line 55, ../../app/features/messageLog/messageLog.scss */
  #messageLog .help-box-container {
    padding-right: 0px;
  }
}
/* line 60, ../../app/features/messageLog/messageLog.scss */
#messageLog .row .row {
  margin-left: -15px;
  margin-right: -15px;
}
/* line 66, ../../app/features/messageLog/messageLog.scss */
#messageLog sa-help-box.shrink .help-body {
  max-height: 122px;
  overflow-y: scroll;
}
/* line 72, ../../app/features/messageLog/messageLog.scss */
#messageLog #helpBox {
  display: block !important;
}
/* line 76, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu {
  top: 145px;
  left: 15px;
  width: 238px;
  padding: 4px;
}
/* line 82, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu .uib-title {
  opacity: 1.0;
  text-align: center;
}
/* line 88, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu .uib-day button {
  padding: 4px 9px;
  min-width: inherit;
  border-width: 0px;
  border-radius: 0px;
  width: 100%;
}
/* line 96, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu .uib-day .text-info {
  color: black;
}
/* line 100, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu .uib-day button:active, #messageLog .uib-datepicker-popup.dropdown-menu .uib-day button.active {
  box-shadow: inherit;
}
/* line 105, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu .uib-day button.active .text-info, #messageLog .uib-datepicker-popup.dropdown-menu .uib-day button.active:active .text-info {
  color: white !important;
}
/* line 111, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu .uib-day button:active .text-info {
  color: black !important;
}
/* line 116, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu .uib-day button[disabled] {
  visibility: hidden;
}
/* line 121, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu button.uib-clear {
  display: none;
}
/* line 125, ../../app/features/messageLog/messageLog.scss */
#messageLog .uib-datepicker-popup.dropdown-menu button.uib-datepicker-current {
  border-radius: 3px !important;
}
/* line 131, ../../app/features/messageLog/messageLog.scss */
#messageLog .grid .no-data {
  padding: 5px;
  height: 30px;
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 30px;
}
/* line 140, ../../app/features/messageLog/messageLog.scss */
#messageLog .grid .ui-grid-column-menu-button {
  display: none;
}

/* line 147, ../../app/features/messageLog/messageLog.scss */
#messageLogmessage th {
  padding-right: 10px;
  vertical-align: top;
}
/* line 152, ../../app/features/messageLog/messageLog.scss */
#messageLogmessage .modal-body {
  white-space: pre-wrap;
}

/* line 2, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .block-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.1;
}
/* line 11, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .grid-margin {
  margin-top: 15px;
  margin-bottom: 15px;
}
/* line 17, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .grid .no-data {
  padding: 5px;
  height: 30px;
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 60px;
}
/* line 26, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .grid .ui-grid-column-menu-button {
  display: none;
}
/* line 32, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .uib-tab a {
  color: #555555;
}
/* line 37, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .active-tab {
  font-weight: bold;
}
/* line 41, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .ui-grid-icon-up-dir {
  display: none;
}
/* line 45, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .ui-grid-icon-down-dir {
  display: none;
}
/* line 49, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .ui-grid-viewport {
  overflow-anchor: none;
}
/* line 53, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .unavailable-reason {
  text-align: center;
}
/* line 56, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .unavailable-reason button, #dcs-submission .unavailable-reason div {
  margin-top: 5px;
}
/* line 61, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .export-button {
  margin-bottom: 15px;
  min-width: 120px;
  text-align: center;
  margin-left: 5px;
}
/* line 68, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .send-button {
  min-width: 130px;
  text-align: center;
}
/* line 73, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .selection-count {
  top: 1px;
  position: relative;
  left: 8px;
}
/* line 79, ../../app/features/DCS/dataCollection.scss */
#dcs-submission .pending-status {
  cursor: not-allowed;
  color: rgba(51, 51, 51, 0.65);
  box-shadow: none;
  background-color: rgba(243, 243, 243, 0.65);
}

.yt-hide{
  display: none!important;
}

.react-grid-Canvas{
  overflow-x: hidden!important;
}

.btn.active {
  border: solid 1px rgb(75, 65, 114)!important;
  background-color: rgb(248, 246, 253)!important;
  color: black!important;
  box-shadow: inset 0 0 3px rgba(87, 75, 136, 0.685)!important;
  -moz-box-shadow: inset 0 0 3px rgba(87, 75, 136, 0.685)!important;
  -webkit-box-shadow: inset 0 0 3px rgba(87, 75, 136, 0.685)!important;
}

.btn-primary {
  color: #fff;
  background-color: rgb(112, 97, 172)!important;
  border-color: rgb(60, 52, 92)!important;
}

.btn-primary:hover {
  color: #fff;
  background-color: rgb(96, 83, 146)!important;
  border-color: rgb(52, 45, 80)!important;
}