table.data-list{
   background-color: #e6e6e6;
   width: 100%;

   thead{
      background-color: #fff;
      th{
         padding: 10px;
      }
   }
   border-bottom: solid 1px #ccc;
   border-left: solid 1px #ccc;
   border-right: solid 1px #ccc;
}

tr.data-list-item{
   background-color: #fff;
   margin-top: 2px;
   
   min-height: 40px;
}