.widget-container.coming-soon{
   overflow: hidden;
}

.widget-container{
   .widget{
      position: relative;
      border: solid 1px #CCC;
      height: 450px;

      $headerHight: 50px;
      .widget-header{
         width: 100%;
         height: $headerHight;
         border-bottom: solid 1px #CCC;

         padding-left: 15px;
         padding-right: 4px;
         display: inline-grid;

         .chart-help{
            display: inline-block;
            margin-left: 10px;
            font-size: 16px;
            cursor: pointer;
            color:#888;
            .chart-help-text{
               color:#333;
               display: none;
               position: absolute;
               border: solid 1px #ccc;
               background-color: #fff;
               padding: 10px 15px;
               font-size: 15px;
               line-height: 20px;
               max-width: 400px;
               word-wrap: normal;
               z-index: 4000;
               margin-top:-10px;
               text-overflow: unset;
               white-space: initial;

               ul{
                  margin-top: 10px;
                  list-style: none;
                  padding-left: 0;
               }
            }
         }
         .chart-help:hover{
            .chart-help-text{
               display: block!important;
            }
         }

         .dropster{
            margin-top: -8px;
         }

         text-align: left;
         .widget-title{
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: $headerHight;
            font-size: 20px;
         }

         .widget-drop{
            line-height: $headerHight;
            float: right;
         }

         .widget-header-cell{
            display: inline-block;
         }
      }

      .widget-content{
         overflow: hidden;
         max-height: 398px;
         
         .expand-button{
            position: absolute;
            left:0;
            bottom:0;
            border: solid 1px #CCC; 
            border-bottom: none;
            border-left: none;
            color: #333!important;
            text-decoration: none!important;
            padding: 5px;
            z-index: 1999;
         }
      }

      .widget-spinner{
         margin-left: auto;
         margin-right: auto;
         margin-top: 85px;
         height: 170px;
         display: block;
      }
      .widget-error, .widget-coming-soon{
         color: #888;
         text-align: center;
         margin-top:200px;
         margin-top:85px;
         display: block;
         font-size: 30px;
         .icon-not-handed-in{
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
            font-size: 130px;
            display: block;
         }
         overflow: hidden;
      }

      .widget-coming-soon{
         transform: rotate(20deg);
         font-size: 80px;
      }

   }   
}

.widget-container{
   .widget.expanded{
      position: fixed;   
      left:0;
      top: 0;
      z-index: 2000;
      background-color: #fff;
      width: 100%;
      height: 100%;

      .widget-content{
         overflow: hidden;
         max-height: none!important;

         .expand-button{
            z-index: 3000;
            padding: 15px;
         }
      }
      .widget-spinner{
         margin-top: 170px;
      }

   }
}
