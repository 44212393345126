#no-attachments{
   background-color: #fff;
   height: 100%;

   color:#888;

   text-align: center;

   .no-attachments-title{
      margin-top: 60px;
      display: inline-block;
      font-size: 30px;
   }

}