
#not-handed-in{
   background-color: #fff;
   height: 100%;

   color:#888;

   text-align: center;

   .not-handed-in-title{
      margin-top: 60px;
      display: inline-block;
      font-size: 30px;
   }

   .icon-not-handed-in{
      margin-top: 30px;
      display: block;
      font-size: 200px;
      
   }

   .not-handed-in-question{
      margin-top: 30px;
      display: inline-block;
      font-size: 20px;
   }
}