@import './variables.scss';

#homework-teacher-view{

   .homework-teacher-expandable-viewer-container.expanded{
      position: absolute;
      width: 100%;
      height: 100%;
      left:0;
      margin: 0;
      top:0;
      z-index: 2000;
   }
   .homework-teacher-expandable-viewer-container.expanded .homework-teacher-expandable-viewer{
      height:100%;
   }

   .homework-teacher-expandable-viewer-container{

      position: relative;
      width: 100%;
      padding: 6px;
      background-color: $controlGrey;

      .homework-teacher-expandable-viewer{

         width: 100%;
         height:$totalNonExpandedHeight;
         display: table;

         .row{
            display: table-row;

            .homework-teacher-expandable-viewer-left-column{
               display: table-cell;

               width:300px;
            }
      
            .homework-teacher-expandable-viewer-pane{
               height: 100%;
               display: table-cell;
               vertical-align: middle;
            }
         }
      }
   }
}

.flex-box-columns{
   display: flex;
   flex-flow: column;
   height: 100%;

   .flex-box-header{
      flex: 0 1 auto;
   }
   .flex-box-content{
      flex: 1 1 auto;
   }
   .flex-box-footer{
      flex: 0 1 auto;
   }
}