.page-selector{
    text-align: right;
}

.page-selector a{
    color: #333333;
    padding: 6px 10px;
    background-color: #fff;
    user-select: none;
    outline: none!important;
    text-decoration: none!important;
}


.page-selector a:hover{
    color: #000;
    background-color: #ccc;
    text-decoration: none;
}


.page-selector li{
    list-style: none;
    display: inline;
    user-select: none;
}

.page-selector .pagination-container{
    display:inline-block;
    padding-left: 0;
    padding: 5px;
    margin: 0;
    margin-top: -4px;
    list-style: none;
    border: solid 4px #e6e6e6;
}

.page-selector .break-me{
    background-color: #fff;
    padding: 6px 8px;
}
.page-selector .pagination-active a{
    border: solid 1px #574B85;
}