.field-validation-message{
    background-color: #ffb6ce75;
    padding: 0 10px;
    margin-top: 5px;
    line-height: 29px;
    margin-bottom: 0px;
    display: inline-block;
    color: #8d1324;
    margin-left: 5px;
}

.field-validation-message:first-child{
    margin-left: 0;
}

.field-validation-message:first-of-type{
    margin-left: 0;
}